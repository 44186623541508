import React from "react";

import PropTypes from "prop-types";
import { CardImage } from "./style";

/**
 * Common Image component for rendering the images in pages
 * @param {string} img
 * @param {string} alt
 * @param {string} height
 * @param {string} width
 * @param {string} mobWidth
 * @param {string} mobHeight
 * @param {boolean} isObjectContain
 * @returns
 */
const ImageCard = props => {
  const { img, alt, height, width, mobWidth, mobHeight, isObjectContain } =
    props;
  return (
    <CardImage
      src={img}
      alt={alt}
      isObjectContain={isObjectContain}
      height={height}
      width={width}
      mobWidth={mobWidth}
      mobHeight={mobHeight}
    />
  );
};

ImageCard.propTypes = {
  img: PropTypes.string,
  alt: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  mobWidth: PropTypes.string,
  mobHeight: PropTypes.string,
  isObjectContain: PropTypes.bool
};

ImageCard.defaultProps = {
  alt: "",
  height: "auto",
  width: "100%",
  mobWidth: "100%",
  mobHeight: "auto"
};

export default ImageCard;
