import React from "react";
import PropTypes from "prop-types";

import { DictionaryContext } from "../../providers/DictionaryContext";
import Strings from "../../resources/strings";
import {
  CompanyDetailsWrapper,
  CompanyDetailsList,
  CompanyDetailsListItem,
  CompanyHeader,
  CompanyDescription
} from "./style";

/**
 * CompanyDetails component is used to render company name and company description in provider summary details
 * @param {Object} selectedProvider
 
 * @example
 * CompanyDetails({
    selectedProvider
   })
 */
const CompanyDetails = ({ selectedProvider }) => {
  const {
    Inception_x0020_Year,
    ComputedField_Industry,
    CategoryArray,
    PartnerShip_x0020_Potential,
    Country,
    City,
    EmployeesStrength
  } = selectedProvider;
  return (
    <DictionaryContext.Consumer>
      {({ dictionary }) => {
        return (
          <CompanyDetailsWrapper>
            <CompanyDetailsList>
              <CompanyDetailsListItem>
                <CompanyHeader>
                  {dictionary?.inception || Strings.inception}
                </CompanyHeader>
                <CompanyDescription>{Inception_x0020_Year}</CompanyDescription>
              </CompanyDetailsListItem>
              <CompanyDetailsListItem>
                <CompanyHeader>
                  {dictionary?.industry || Strings.industry}
                </CompanyHeader>
                <CompanyDescription isIndustry={true}>
                  {ComputedField_Industry}
                </CompanyDescription>
              </CompanyDetailsListItem>
              <CompanyDetailsListItem>
                <CompanyHeader>
                  {dictionary?.categoryTitle || Strings.categoryTitle}
                </CompanyHeader>
                <CompanyDescription isCategory={true}>
                  {CategoryArray?.length > 1
                    ? CategoryArray?.join(", ")?.trim()?.slice(0, -1)
                    : CategoryArray}
                </CompanyDescription>
              </CompanyDetailsListItem>
              <CompanyDetailsListItem>
                <CompanyHeader>
                  {dictionary?.partnershipPotential ||
                    Strings.partnershipPotential}
                </CompanyHeader>
                <CompanyDescription>
                  {PartnerShip_x0020_Potential}
                </CompanyDescription>
              </CompanyDetailsListItem>
              <CompanyDetailsListItem>
                <CompanyHeader>
                  {dictionary?.location || Strings.location}
                </CompanyHeader>
                <CompanyDescription isLocation={true}>
                  {City + "," + Country}
                </CompanyDescription>
              </CompanyDetailsListItem>
              <CompanyDetailsListItem>
                <CompanyHeader>
                  {dictionary?.people || Strings.people}
                </CompanyHeader>
                <CompanyDescription>{EmployeesStrength}</CompanyDescription>
              </CompanyDetailsListItem>
            </CompanyDetailsList>
          </CompanyDetailsWrapper>
        );
      }}
    </DictionaryContext.Consumer>
  );
};

CompanyDetails.propTypes = {
  selectedProvider: PropTypes.object
};

export default CompanyDetails;
