import { Modal } from "react-bootstrap";
import styled from "styled-components";

import { Button, fadeInAnimation } from "../../../styles/commonStyles";

export const ProfileContainer = styled.div``;

export const CustomModal = styled(Modal)`
  animation-name: ${fadeInAnimation};
  animation-duration: 0.3s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;

  @media ${props => props.theme.breakPoints.mobileDevice} {
    animation-name: none;
  }
  &.fade {
    transition: none;
  }
  .modal-dialog {
    position: absolute;
    right: 20px;
    width: 320px;
    margin-top: 32px;
    @media ${props => props.theme.breakPoints.mobileDevice} {
      margin: 24px 0 0;
      width: 100%;
      right: 0;
      padding: 0 20px;
      height: calc(100vh - 20px);
      bottom: -100%;
      transition: all 0.3s ease-in-out;
    }
  }
  &.fade.show .modal-dialog {
    transition: none;
    @media ${props => props.theme.breakPoints.mobileDevice} {
      bottom: 0px;
      transition: all 0.3s ease-in-out;
    }
  }
  .modal-content {
    border: 0;
    border-radius: 24px;
    background-color: transparent !important;
  }
  .modal-body {
    padding: 0;
  }
`;

export const Profile = styled.div`
  cursor: pointer;
  background-color: ${({ theme }) => theme.backgroundColor.overlay};
  position: relative;
  display: inline-block;
  &,
  & > img {
    border-radius: 50%;
    width: 48px;
    height: 48px;
    object-fit: cover;
  }
`;

export const PopupContainer = styled.div`
  background-color: ${props => props.theme.backgroundColor.white};
  border-radius: 20px;
  & > :last-child {
    padding: 42px 20px;
  }
  @media ${props => props.theme.breakPoints.mobileDevice} {
    display: flex;
    flex-direction: column;
    & > :last-child {
      padding: 32px 20px;
    }
  }
`;

export const UserDetailsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  @media ${props => props.theme.breakPoints.mobileDevice} {
    padding: 25px 24px 24px;
    bottom: 0;
    width: 100%;
  }
`;

export const InfoContainer = styled.div`
  width: 80%;
  word-break: break-all;
`;

export const UserName = styled.div`
  color: ${props => props.theme.fontColor.secondary};
  font-size: ${props => props.theme.fontSize.connect};
  font-weight: ${props => props.theme.fontWeight.bold};
  line-height: ${props => props.theme.lineHeight.default};
  margin-bottom: 2px;
`;

export const EmailId = styled.div`
  font-size: ${props => props.theme.fontSize.tertiary};
  color: ${props => props.theme.fontColor.manatee};
  line-height: ${props => props.theme.lineHeight.normal};
`;

export const SignOutButton = styled(Button)`
  font-size: ${props => props.theme.fontSize.primary};
  border: solid 1px ${props => props.theme.border.royalBlue};
  background-color: transparent;
  padding: 16px;
  width: 100%;
  max-height: 52px;
`;
