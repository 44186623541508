import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";

import { StateContext } from "../../providers/StateContext";
import ProvidersFilterResults from "./ProvidersFilterResults";
import SummaryFilterResults from "./SummaryFilterResults";
import Strings from "../../resources/strings";
import { PageContainer } from "../../styles/commonStyles";

/**
 * ProviderResult component gives the information about the provider search results
 * @param {object} selectedProvider
 * @param {Array} providers
 * @returns
 */
const ProvidersResult = props => {
  const { searchedData } = props;

  const state = useContext(StateContext);
  const providersData = state.providers;

  const [providersFilterResult, setProvidersFilterResult] = useState([]);
  const [summaryFilterResult, setSummaryFilterResult] = useState({});
  const [summaryFilterArray, setSummaryFilterArray] = useState([]);
  const [filterText, setFilterText] = useState(null);

  useEffect(() => {
    let providersFilterResult = [];
    let summaryFilterResult = [];
    let filterText = "";

    switch (searchedData.filterType) {
      case Strings.companyCategory:
        providersFilterResult = providersData.filter(
          data =>
            data?.CategoryArray[data?.CategoryArray.indexOf(searchedData.title)]
        );
        break;
      case Strings.companyIndustry:
        providersFilterResult = providersData.filter(
          data => data?.ComputedField_Industry === searchedData.title
        );
        break;
      case Strings.categoryAndCompany:
        summaryFilterResult = providersData.filter(
          data =>
            data?.CategoryArray[
              data?.CategoryArray.indexOf(searchedData.subTitle0)
            ] && data?.Title === searchedData.subTitle1
        );
        providersFilterResult = providersData.filter(
          data =>
            JSON.stringify(data?.CategoryArray) ===
              JSON.stringify(summaryFilterResult[0]?.CategoryArray) &&
            data?.Title !== summaryFilterResult[0]?.Title
        );
        filterText = Strings.categoryFilterText;
        break;
      case Strings.industryAndCompany:
        summaryFilterResult = providersData.filter(
          data =>
            data?.ComputedField_Industry === searchedData.subTitle0 &&
            data?.Title === searchedData.subTitle1
        );
        providersFilterResult = providersData.filter(
          data =>
            data.ComputedField_Industry ===
              summaryFilterResult[0]?.ComputedField_Industry &&
            data?.Title !== summaryFilterResult[0]?.Title
        );
        filterText = Strings.industryFilterText;
        break;
      default:
        summaryFilterResult = providersData.filter(
          data => data?.Title === searchedData.title
        );
        providersFilterResult = providersData.filter(
          data =>
            JSON.stringify(data?.CategoryArray) ===
              JSON.stringify(summaryFilterResult[0]?.CategoryArray) &&
            data?.Title !== summaryFilterResult[0]?.Title
        );
        filterText = Strings.categoryFilterText;
        break;
    }

    setSummaryFilterResult(summaryFilterResult[0]);
    setProvidersFilterResult(providersFilterResult);
    setSummaryFilterArray(summaryFilterResult);
    setFilterText(filterText);
  }, [searchedData, providersData]);

  return (
    <PageContainer>
      {summaryFilterResult && (
        <SummaryFilterResults
          filterResult={summaryFilterResult}
          summaryFilterArray={summaryFilterArray}
          searchedData={searchedData}
        />
      )}
      {providersFilterResult.length > 0 && (
        <ProvidersFilterResults
          filterResult={providersFilterResult}
          filterText={filterText}
          searchedData={searchedData}
        />
      )}
    </PageContainer>
  );
};

ProvidersResult.propTypes = {
  searchedData: PropTypes.instanceOf(Object)
};

export default ProvidersResult;
