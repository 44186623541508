import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";

import Images from "../../resources/images";
import Employees from "./Employees";
import LineSeparator from "../CommonComponents/LineSeparator/LineSeparator";
import CompanyDetails from "./CompanyDetails";
import ProviderButtons from "./ProviderButtons";
import DetailsSummaryVariation from "./DetailsSummaryVariation";
import ImageCard from "../CommonComponents/ImageCard/ImageCard";
import { CardImage } from "../CommonComponents/ImageCard/style";
import Strings from "../../resources/strings";
import { DictionaryContext } from "../../providers/DictionaryContext";
import {
  EmployeeWrapper,
  NavAndButtonWrapper,
  SummaryCardImageWrapper,
  SummaryCardTagWrapper,
  SummaryContainer
} from "./style";
import { Anchor, CardTags } from "../../styles/commonStyles";
import { getRefactoredImageUrl } from "../../utils/commonUtils";

/**
 * ProviderDetailsSummary component is used to render details of a particular provider when selected
 * @param {bool} isSearch
 * @param {object} selectedProvider
 * @param {Function} handleScroll
 * @param {Array} filteredHighlights
 * @param {Object} contacts
 * @returns
 */
const ProviderDetailsSummary = ({
  isSearch,
  provider,
  handleScroll,
  filteredHighlights,
  contacts
}) => {
  const [mainContact, setMainContact] = useState([]);
  useEffect(() => {
    if (contacts) {
      let mainContactTable = [];
      provider?.Contact_x0020_Details?.forEach(contact => {
        contacts[contact?.LookupId]["Is_x0020_Main_x0020_contact"] &&
          mainContactTable.push(contacts[contact?.LookupId]);
      });
      setMainContact(mainContactTable);
    }
  }, [contacts, provider]);
  return (
    <DictionaryContext.Consumer>
      {({ dictionary }) => {
        return (
          <SummaryContainer>
            <SummaryCardImageWrapper searchValue={isSearch}>
              <ImageCard
                img={getRefactoredImageUrl(provider?.Company_x0020_Logo?.Url)}
                isObjectContain={true}
                height="59px"
                width="fit-content"
                mobHeight="59px"
                mobWidth="fit-content"
                alt="provider-logo"
              />
            </SummaryCardImageWrapper>
            {!isSearch && (
              <EmployeeWrapper>
                <Employees mainContact={mainContact} />
              </EmployeeWrapper>
            )}
            <LineSeparator />
            <CompanyDetails selectedProvider={provider} />
            <LineSeparator />
            {!isSearch ? (
              <Fragment>
                <SummaryCardTagWrapper isDetails={true}>
                  {provider?.Highlights?.map((highlight, index) => {
                    return (
                      <CardTags key={index}>{highlight?.LookupValue}</CardTags>
                    );
                  })}
                </SummaryCardTagWrapper>
                <NavAndButtonWrapper>
                  <Anchor
                    href={provider?.Website_x0020_link?.Url}
                    target="_blank"
                    textcolor={"#4151d9"}>
                    {dictionary?.companyWebsite || Strings.companyWebsite}
                    <CardImage
                      src={Images.RightArrow}
                      width="16px"
                      height="16px"
                      mobWidth="16px"
                      mobHeight="16px"></CardImage>
                  </Anchor>
                  <ProviderButtons handleScroll={handleScroll} />
                </NavAndButtonWrapper>
              </Fragment>
            ) : (
              <DetailsSummaryVariation
                redirectTo={"/auth/providers/details?id=" + provider?.id}
                filteredHighlights={filteredHighlights}
                linkText={dictionary?.readMore || Strings.readMore}
                description={provider?.Abstract}
              />
            )}
          </SummaryContainer>
        );
      }}
    </DictionaryContext.Consumer>
  );
};

ProviderDetailsSummary.propTypes = {
  isSearch: PropTypes.bool,
  selectedProvider: PropTypes.object,
  handleScroll: PropTypes.func,
  filteredHighlights: PropTypes.array,
  contacts: PropTypes.object
};

export default ProviderDetailsSummary;
