import React, { useContext } from "react";
import PropTypes from "prop-types";

import { useStaticQuery, graphql } from "gatsby";

import { Location } from "@reach/router";

import CustomLink from "../CommonComponents/Link/Link";

import { Navbar, NavItem, NavImage, NavText, LinkWrapper } from "./style";
import { DictionaryContext } from "../../providers/DictionaryContext";
import { getRefactoredImageUrl } from "../../utils/commonUtils";

/**
 * Menu component will provide different navbar items
 * @param {array} data - define list which will be map
 * @param {bool} clicked - define list item is clicked or not
 * @example
 * Menu({data, clicked, show})
 */
const navItemImages = [
  {
    image: "ExploreInActive",
    activeImage: "ExploreActive",
    altText: "explore-navigation"
  },
  {
    image: "ProviderInActive",
    activeImage: "ProviderActive",
    altText: "provider-navigation"
  },
  {
    image: "CapabilitiesInActive",
    activeImage: "CapabilitiesActive",
    altText: "capabilities-navigation"
  }
];

const navigationItemKey = {
  0: "Explore",
  1: "Providers",
  2: "Capabilities"
};

const activeLink = ({ currentLocation, item, index }) => {
  const currentPath = currentLocation.split("/");
  const itemLink = item.link ? item.link.split("/") : "";
  let location1 = "";
  let location2 = "";
  if (currentPath.length > 2) {
    location1 = currentPath[2];
  }
  if (itemLink.length > 2) {
    location2 = itemLink[2];
  }

  const activeItem =
    location1 === location2
      ? { image: navItemImages[index]?.activeImage, status: true }
      : { image: navItemImages[index]?.image, status: false };

  return activeItem;
};

export const Menu = props => {
  const { data, clicked, show, mobileView } = props;
  const { dictionary } = useContext(DictionaryContext);
  return (
    <Location>
      {({ location }) => (
        <Navbar show={show} mobileView={mobileView}>
          {data.map((item, index) => {
            const processNavItem = activeLink({
              currentLocation: location.pathname,
              item: item,
              index: index
            });
            return (
              <NavItem key={item.id} mobileView={mobileView}>
                <CustomLink
                  to={item.link}
                  onClick={clicked}
                  mobileView={mobileView}>
                  <LinkWrapper>
                    <NavImage
                      activeClassName="active"
                      src={getRefactoredImageUrl(
                        dictionary[processNavItem.image]
                      )}
                      alt={navItemImages[index]?.altText}
                      active={processNavItem.status}
                      index={index}
                    />
                    <NavText active={processNavItem.status}>
                      {navigationItemKey[index] || item.linkName}
                    </NavText>
                  </LinkWrapper>
                </CustomLink>
              </NavItem>
            );
          })}
        </Navbar>
      )}
    </Location>
  );
};

const NavigationMenu = props => {
  const navBarData = useStaticQuery(graphql`
    query {
      allJsonData {
        nodes {
          navigation {
            id
            link
            linkName
            active
          }
        }
      }
    }
  `);

  const { clicked, mobileView } = props;
  let menuData = [];
  navBarData.allJsonData.nodes.forEach(element => {
    menuData = element.navigation ? element.navigation : menuData;
  });

  return <Menu data={menuData} clicked={clicked} mobileView={mobileView} />;
};

Menu.propTypes = {
  data: PropTypes.array,
  clicked: PropTypes.func,
  mobileView: PropTypes.bool
};

export default NavigationMenu;
