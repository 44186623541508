export const isBrowser = typeof window !== "undefined";

export const isIE = () => {
  const ua = isBrowser ? window.navigator.userAgent : [];
  const msie = ua.indexOf("MSIE ") > -1;
  const msie11 = ua.indexOf("Trident/") > -1;

  return msie || msie11;
};

export const msalConfig = {
  auth: {
    clientId: process.env.CLIENT_ID,
    authority: `https://login.microsoftonline.com/${process.env.TENANT_ID}`,
    redirectUri: process.env.LOGIN_REDIRECT_URL,
    validateAuthority: true,
    navigateToLoginRequestUrl: true
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: isIE()
  },
  system: {
    telemetry: {
      applicationName: "SBG-UnfairAdvantage",
      applicationVersion: "1.0.0",
      telemetryEmitter: events => {}
    }
  }
};
