import React from "react";
import PropTypes from "prop-types";

import BreadCrumb from "../BreadCrumb/BreadCrumb";
import CustomLink from "../CommonComponents/Link/Link";
import Search from "../Search/Search";
import ProfileAvatar from "../CommonComponents/ProfileAvatar/ProfileAvatar";
import { breadCrumbData } from "../../utils/titleConfig";
import {
  HeaderWrapper,
  LogoWrapper,
  Wrapper,
  MobileWrapper,
  MobileHeaderWrapper,
  DesktopHeaderWrapper
} from "./style";
import { DictionaryContext } from "../../providers/DictionaryContext";
import { getRefactoredImageUrl } from "../../utils/commonUtils";

/**
 * Header component defines header part of layout which shows user avatar
 * @example
 * Header()
 */
const Header = props => {
  const { showSearch, getData } = props;

  return (
    <HeaderWrapper>
      <MobileHeaderWrapper>
        <DictionaryContext.Consumer>
          {({ dictionary }) => {
            return (
              <MobileWrapper>
                <CustomLink to="/auth/explore">
                  <LogoWrapper
                    src={getRefactoredImageUrl(dictionary?.LogoMobile)}
                    alt="sbg-logo"
                  />
                </CustomLink>
                <ProfileAvatar />
              </MobileWrapper>
            );
          }}
        </DictionaryContext.Consumer>

        {!showSearch ? (
          <BreadCrumb links={breadCrumbData} />
        ) : (
          <Search getData={getData} />
        )}
      </MobileHeaderWrapper>
      <DesktopHeaderWrapper>
        <Wrapper showSearch={showSearch}>
          {!showSearch ? (
            <BreadCrumb links={breadCrumbData} />
          ) : (
            <Search getData={getData} />
          )}
          <ProfileAvatar />
        </Wrapper>
      </DesktopHeaderWrapper>
    </HeaderWrapper>
  );
};

Header.propTypes = {
  getData: PropTypes.func
};

export default Header;
