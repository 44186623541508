import Images from "../resources/images";

/**
 * isBrowser will check window type and return boolean value
 * @example
 * isBrowser
 */
export const isBrowser = typeof window !== "undefined";

/**
 * Function to convert the string into uppercase format
 * @param {string} text to convert to uppercase
 * @example convertToUpperCase("sample")
 * @returns SAMPLE
 */

export const convertToUpperCase = text => text?.toUpperCase();

/**
 * Function to fetch the name by id
 * @param {Array} dataArray
 * @param {String} id
 * @param {String} key
 */

export const fetchNameById = (dataArray, id, key) => {
  return dataArray.filter(data => data[key] === id);
};

/**
 * Function to get the user id
 * @returns
 */

export const getUserId = () => {
  return isBrowser && window.sessionStorage.getItem("userId");
};

/**
 * Function to get the user name
 * @returns
 */

export const getUserName = () => {
  return isBrowser && window.sessionStorage.getItem("userName");
};

/**
 * Function to refactor the url to storage
 * @param {String} url
 */

export const getRefactoredImageUrl = url =>
  url &&
  process.env.IMAGE_BASE_URL +
    url.split(process.env.SHAREPOINT_RELATIVE_PATH_SBGAI).pop() +
    process.env.SAS_KEY;

/**
 * Function to update the Video,Image,Iframe and Download Docs
 * @param {String} description
 */

export const decodeHTMLMarkup = description => {
  if (description) {
    const updatedDescription = new DOMParser().parseFromString(
      description,
      "text/html"
    );
    if (updatedDescription.querySelector("video")) {
      const videos = updatedDescription.querySelectorAll("video");
      videos.forEach(video => {
        let source = video.querySelector("source").getAttribute("src");
        if (source) {
          const url = getRefactoredImageUrl(source);
          video.querySelector("source").setAttribute("src", url);
        }
        video.setAttribute("class", "plyr-video");
      });
    }
    if (updatedDescription.querySelector("img")) {
      let images = updatedDescription.querySelectorAll("img");
      images.forEach(image => {
        let source = image.getAttribute("src");
        if (source) {
          const url = getRefactoredImageUrl(source);
          image.setAttribute("src", url);
        }
      });
    }
    if (updatedDescription.querySelector("a")) {
      let anchorLinks = updatedDescription.querySelectorAll("a");
      anchorLinks.forEach(anchorLink => {
        let source = anchorLink.getAttribute("href");
        if (source.indexOf(process.env.SHAREPOINT_RELATIVE_PATH_SBGAI) !== -1) {
          anchorLink.setAttribute("download", "true");
          anchorLink.style.cssText =
            "text-decoration:none;color:#4151D9;font-weight:bold";
          let downloadIcon = document.createElement("img");
          downloadIcon.style.cssText =
            "width:10px;margin:0 0 0 12px;color:#4151D9;height:10px;border-radius:0";
          downloadIcon.setAttribute("src", Images.DownloadImg);
          anchorLink.after(downloadIcon);
        } else {
          anchorLink.style.cssText = "color:#4151D9;font-weight:bold";
          anchorLink.setAttribute("target", "_blank");
          anchorLink.setAttribute("rel", "noopener noreferrer");
          let redirectIcon = document.createElement("img");
          redirectIcon.style.cssText =
            "width:10px;margin:0 0 0 12px;color:#4151D9;height:10px;border-radius:0";
          redirectIcon.setAttribute("src", Images.RedirectImage);
          anchorLink.after(redirectIcon);
        }
      });
    }
    if (updatedDescription.querySelector("iframe")) {
      let iframeElements = updatedDescription.querySelectorAll("iframe");
      iframeElements.forEach(iframeElement => {
        // create wrapper container
        let wrapper = document.createElement("div");
        wrapper.classList.add("plyr-video");
        wrapper.classList.add("plyr__video-embed");
        wrapper.setAttribute("data-plyr-provider", "youtube");
        wrapper.setAttribute(
          "data-plyr-embed-id",
          iframeElement.getAttribute("src")
        );
        // insert wrapper before iframeElement in the DOM tree
        iframeElement.parentNode.insertBefore(wrapper, iframeElement);
        // move iframeElement into wrapper
        wrapper.appendChild(iframeElement);
      });
    }
    return updatedDescription.getElementsByTagName("html")[0].innerHTML;
  }
};

/**
 * function to get the assetSubcategory or assetName ids
 * @param {string} type
 * @returns
 */
export const getCapabilitiesId = type => {
  return (
    type &&
    type
      ?.replace(/[&,#@()!]/g, "-")
      ?.split(" ")
      ?.join("_")
  );
};
