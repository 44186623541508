import styled from "styled-components";

import {
  CardDescription,
  CardWrapper,
  CardTitle
} from "../../styles/commonStyles";

export const SearchInputWrapper = styled.div`
  width: 100%;
  min-height: 52px;
  display: flex;
  position: relative;
  top: 0px;
`;

export const SearchInput = styled.input`
  width: 100%;
  border-radius: 8px 8px
    ${props =>
      props.currentOption >= 0 ||
      props.filteredOptions.filteredProviderOptions.length > 0 ||
      props.filteredOptions.filteredCapabilitiesOptions.length > 0
        ? "0px 0px"
        : "8px 8px"};

  box-shadow: ${props => props.theme.boxShadow.search};
  border: solid 1px ${props => props.theme.border.divider};
  background-color: ${props => props.theme.backgroundColor.white};
  text-indent: ${props => (props.searchInput?.length > 0 ? "unset" : "52px")};
  padding-inline-start: ${props =>
    props.searchInput?.length > 0 ? "52px" : "unset"};
  padding-inline-end: ${props =>
    props.searchInput?.length > 0 ? "60px" : "unset"};
  outline: none;
  padding-right: ${props => (props.searchInput?.length > 0 ? "55px" : "42px")};
  ::placeholder {
    font-size: ${props => props.theme.fontSize.primary};
    line-height: ${props => props.theme.lineHeight.content};
    font-weight: ${props => props.theme.fontWeight.bold};
    color: ${props => props.theme.fontColor.placeholderColor};
  }
  border-bottom: ${props =>
    props.searchInput?.length > 0
      ? "none"
      : `solid 1px ${props => props.theme.border.divider}`};
  text-overflow: ellipsis;
  @media ${props => props.theme.breakPoints.mobileDevice} {
    text-indent: ${props => (props.searchInput?.length > 0 ? "unset" : "40px")};
    padding-inline-start: ${props =>
      props.searchInput?.length > 0 ? "38px" : "unset"};
    padding-inline-end: ${props =>
      props.searchInput?.length > 0 ? "38px" : "unset"};
  }
  @media ${props => props.theme.breakPoints.tabDevice} {
    text-indent: ${props => (props.searchInput?.length > 0 ? "unset" : "40px")};
    padding-inline-start: ${props =>
      props.searchInput?.length > 0 ? "40px" : "unset"};
    padding-inline-end: ${props =>
      props.searchInput?.length > 0 ? "44px" : "unset"};
  }
`;

export const SearchIconWrapper = styled.div`
  display: flex;
  align-items: center;
  left: 2%;
  position: absolute;
  margin-top: 14px;
`;

export const SearchResultsWrapper = styled.div`
  @media ${props => props.theme.breakPoints.tabDevice} {
    margin-top: 180px;
  }
  @media ${props => props.theme.breakPoints.mobileDevice} {
    margin-top: 180px;
  }
`;

export const SearchParentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 972px;
  & > :last-child {
    margin-top: 32px;
    z-index: 1;
  }
  @media ${props => props.theme.breakPoints.mobileDevice} {
    margin-right: 10px;
  }
  @media ${props => props.theme.breakPoints.tabDevice} {
    margin-right: 15px;
  }
  @media ${props => props.theme.breakPoints.smallDesktopDevice} {
    margin-right: 15px;
  }
  @media ${props => props.theme.breakPoints.mediumDesktopDevice} {
    margin-right: 25px;
  }
  @media ${props => props.theme.breakPoints.largeDesktopDevice} {
    max-width: unset;
    margin-right: 67px;
  }
`;

export const OptionsListWrapper = styled.div`
  position: absolute;
  margin-top: 52px;
  width: 100%;
  border-radius: 0px 0px 8px 8px;
  box-shadow: ${props => props.theme.boxShadow.search};
  border: solid 1px ${props => props.theme.border.divider};
  border-top: none;
  background-color: ${props => props.theme.backgroundColor.white};
  z-index: 4;
  padding: 0px 20px 0px 20px;
  & > :last-child {
    margin-bottom: 0px;
  }
`;

export const OptionsTotalList = styled.ul`
  width: 100%;
  padding-left: 17px;
`;

export const OptionsListItem = styled.li`
  font-size: ${props => props.theme.fontSize.primary};
  line-height: ${props => props.theme.lineHeight.content};
  border-radius: 8px;
  margin-bottom: 9px;
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  &:first-child {
    margin-top: 16px;
  }
  &:last-child {
    margin-bottom: 28px;
  }
  &:hover {
    font-weight: ${props => props.theme.fontWeight.bolder};
  }
  font-weight: ${props =>
    props.currentOption === props.currentRow && props.currentRow >= -1
      ? props.theme.fontWeight.bolder
      : props.theme.fontWeight.normal};
  color: ${props => props.theme.fontColor.activeBlack};
  padding-left: 10px;
`;

export const ClearSearchWrapper = styled.button`
  display: flex;
  align-items: center;
  position: absolute;
  margin-top: 14px;
  right: 3%;
  border: none;
  background-color: ${props => props.theme.backgroundColor.transparent};
`;
export const NoResultsWrapper = styled(CardWrapper)`
  width: 100%;
  border-radius: 16px;
  min-height: 513px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & > :nth-child(2) {
    margin: 39px 0px 12px;
  }
  @media ${props => props.theme.breakPoints.mobileDevice} {
    & > ${CardTitle},& > ${CardDescription} {
      max-width: 80%;
    }
  }
  @media ${props => props.theme.breakPoints.tabDevice} {
    & > ${CardTitle},& > ${CardDescription} {
      max-width: 80%;
    }
  }
`;

export const NoResultsDescription = styled(CardDescription)`
  text-align: center;
  font-size: ${props => props.theme.fontSize.quaternary};
  line-height: ${props => props.theme.lineHeight.description};
`;

export const ResultsText = styled.p`
  margin: ${props => (!props.filterText ? "32px 0 0 0" : "68px 0 0 0")};
  line-height: ${props => props.theme.lineHeight.description};
  font-size: ${props => props.theme.fontSize.quaternary};
  color: ${props => props.theme.fontColor.activeBlack};
  font-weight: ${props => props.theme.fontWeight.bold};
  @media ${props => props.theme.breakPoints.mobileDevice} {
    font-size: ${props => props.theme.fontSize.connect};
    line-height: ${props => props.theme.lineHeight.heading};
    margin: 21px 0 0 0;
  }
  @media ${props => props.theme.breakPoints.mobileDevice} {
    font-size: ${props => props.theme.fontSize.connect};
    line-height: ${props => props.theme.lineHeight.heading};
    margin: 21px 0 0 0;
  }
`;

export const SummaryFilterText = styled(ResultsText)`
  margin: 32px 0 16px 0;
  @media ${props => props.theme.breakPoints.mobileDevice} {
    margin: 21px 0 8px 0;
  }
  @media ${props => props.theme.breakPoints.mobileDevice} {
    margin: 21px 0 8px 0;
  }
`;
