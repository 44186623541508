import React, { useMemo, useState, useEffect } from "react";
import PropTypes from "prop-types";

import Header from "./../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import MobileNavbar from "../MobileNavbar/MobileNavbar";
import SearchResults from "../Search/SearchResults";
import Strings from "../../resources/strings";
import { LayoutWrapper, ContentWrapper, Main } from "./style";

/**
 * Layout component is basic component which have sidebar, header and main section
 * @param {node} children
 * @param {boolean} showSearch
 * @example
 * Layout({children})
 */
const Layout = ({ children, showSearch }) => {
  const showHeader = true;
  const [isSearchVisible, setIsSearchVisible] = useState(true);
  const [isSearched, setIsSearched] = useState(false);
  const [searchedData, setSearchedData] = useState(null);

  useEffect(() => {
    if (isSearchVisible !== showSearch) {
      setIsSearchVisible(showSearch);
      setSearchedData(null);
    }
  }, [showSearch, isSearchVisible]);

  const getData = data => {
    setSearchedData(data);
    data !== null || data === Strings.noMatch
      ? setIsSearched(true)
      : setIsSearched(false);
  };

  return useMemo(
    () =>
      children?.props?.path === "/auth/*" ? (
        <LayoutWrapper>
          <Sidebar />
          <ContentWrapper>
            <Header
              show={showHeader}
              showSearch={showSearch}
              getData={getData}
            />
            {isSearched && showSearch && searchedData ? (
              <SearchResults searchedData={searchedData} />
            ) : (
              <Main showSearch={showSearch}>{children}</Main>
            )}
            <MobileNavbar />
          </ContentWrapper>
        </LayoutWrapper>
      ) : (
        children
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [children, showHeader, isSearchVisible, isSearched, searchedData]
  );
};

Layout.propTypes = {
  children: PropTypes.node,
  showSearch: PropTypes.bool
};

export default Layout;
