import React from "react";
import PropTypes from "prop-types";

import Strings from "../../resources/strings";
import Images from "../../resources/images";
import ImageCard from "../CommonComponents/ImageCard/ImageCard";
import { CardTitle } from "../../styles/commonStyles";
import { NoResultsWrapper, NoResultsDescription } from "./style";

/**
 * NoResults Component will visible when serach item not found
 * @param {string} img
 * @param {string} title
 * @param {string} description
 * @example
 * NoResults({img, title, description})
 */
const NoResults = props => {
  const { img, title, description, width, height, mobHeight, mobWidth } = props;
  return (
    <NoResultsWrapper>
      <ImageCard
        img={img}
        width={width}
        height={height}
        mobHeight={mobHeight}
        mobWidth={mobWidth}
      />
      <CardTitle>{title}</CardTitle>
      <NoResultsDescription>{description}</NoResultsDescription>
    </NoResultsWrapper>
  );
};

NoResults.propTypes = {
  img: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  mobHeight: PropTypes.string,
  mobWidth: PropTypes.string
};

NoResults.defaultProps = {
  width: "320px",
  height: "69px",
  mobHeight: "69px",
  mobWidth: "320px",
  img: Images.NoResultsImg,
  title: Strings.noResults,
  description: Strings.noResultsDescription
};

export default NoResults;
