import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
     * {
        padding: 0;
        margin: 0;
        box-sizing: border-box;
    };
    body{
        background-color:${props => props.theme.backgroundColor.primary0};
        -webkit-font-smoothing: antialiased;
    }
    ol,ul {
        list-style-type: none;
    }
    button:focus-visible {
         outline: ${props => props.theme.fontColor.linkColor} auto 1px;;
    }
    :root {
        --plyr-color-main: ${props => props.theme.backgroundColor.blue};
        --plyr-video-background:${props =>
          props.theme.backgroundColor.transparentBlack};
      }
    
    .plyr__poster{
        background-size:cover !important;
    }
    .plyr__poster:before{
        background-color:rgba(0,0,0,0.3);
        width:100%;
        height:100%;
        content:"";
        display: block;
        position: absolute;
    }
    .plyr--video{
        border-radius:16px;
    }
    .plyr__video-wrapper ~ .plyr__control{
        width:72px;
        height:72px;
        border-radius:28.8px;
        @media ${props => props.theme.breakPoints.mobileDevice}{
            width: 36px;
            height: 36px;
        }
    }
    .plyr__control--overlaid svg {
        left: 25% !important;
        width: 26px !important;
        height: 28px !important;
        @media ${props => props.theme.breakPoints.mobileDevice}{
            width: 13px !important;
            height: 20px !important;
            transform: translate(-3px, -7px);
        }
    }
    .plyr--playing ~ #video-title{
        display:none;
    }
    .plyr--stopped .plyr__controls,.plyr--paused .plyr__controls{
     display:none;
    }
    .plyr--playing .plyr__poster, .plyr--paused .plyr__poster {
        display:none;
    }
`;
export default GlobalStyles;
