import styled from "styled-components";

export const RichTextWrapper = styled.span`
  width: inherit;
  height: inherit;
  font-family: ${props => props.theme.fontFamily.default} !important;
  & span,
  & div,
  & p {
    font-family: ${props => props.theme.fontFamily.default} !important;
  }
  & img {
    width: 100%;
    object-fit: cover;
  }
  & table {
    width: 100%;
    overflow-x: auto;
    margin-bottom: 38px;
    @media ${props => props.theme.breakPoints.mobileDevice} {
      margin-bottom: 51px;
    }
    @media ${props => props.theme.breakPoints.tabDevice} {
      margin-bottom: 51px;
    }
  }
  & th {
    padding: 15px 6px 18px 20px;
    min-width: 140px;
    font-size: ${props => props.theme.fontSize.primary};
    font-weight: ${props => props.theme.fontWeight.bold};
    color: ${props => props.theme.fontColor.activeBlack};
    :first-child {
      padding-left: 10px;
    }
    @media ${props => props.theme.breakPoints.tabDevice} {
      font-size: ${props => props.theme.fontSize.secondary};
    }
    @media ${props => props.theme.breakPoints.mobileDevice} {
      font-size: ${props => props.theme.fontSize.secondary};
    }
  }

  & tr {
    th,
    td {
      border: 1px solid ${props => props.theme.border.table};
    }
    & span,
    & p {
      font-size: ${props => props.theme.fontSize.secondary} !important;
    }
    th:first-child,
    td:first-child {
      border-left: none;
    }
    td:first-child {
      font-weight: ${props => props.theme.fontWeight.bold};
      padding-left: 10px;
    }
    th:last-child,
    td:last-child {
      border-right: none;
    }
  }
  & tr:first-child {
    td:first-child {
      font-weight: ${props => props.theme.fontWeight.normal};
      padding-left: 10px;
    }
    & span,
    & p {
      font-size: ${props => props.theme.fontSize.primary} !important;
    }
  }
  & td {
    font-size: ${props => props.theme.fontSize.secondary};
    line-height: ${props => props.theme.lineHeight.content};
    padding: 20px 17px 13px 20px;
    min-width: 140px;
    text-align: left;
    vertical-align: text-top;
    @media ${props => props.theme.breakPoints.mobileDevice} {
      min-width: 122px;
    }
  }
`;
