import React, { useEffect, Fragment, useState } from "react";
import PropTypes from "prop-types";

import BreadCrumb from "../../BreadCrumb/BreadCrumb";
import AssetSubCategoryDetails from "./CapabilitiesSubCategoryDetails";
import { fetchNameById } from "../../../utils/commonUtils";
import Strings from "../../../resources/strings";
import { DictionaryContext } from "../../../providers/DictionaryContext";
import {
  AssetCategoryDetailsWrapper,
  CategoryDescription,
  CategoryTitle
} from "./style";

/**
 * AssetCategoryDetails component contains Asset Details that includes Breadcrumb and Accordion
 * @param {Array} selectedCapability
 * @param {Array} capabilitiesCategory
 * @param {Array} capabilitiesSubCategoryLookupIds
 * @param {Array} providers
 * @param {Function} closeModal
 * @param {Object} searchedData
 * @returns
 */
const AssetCategoryDetails = props => {
  const {
    capabilitiesCategory,
    providers,
    selectedCapability,
    closeModal,
    capabilitiesSubCategoryLookupIds,
    searchedData
  } = props;
  const [subCategory, setSubCategory] = useState({});

  const breadCrumbData = [
    {
      linkName: fetchNameById(
        providers,
        selectedCapability[0]?.ProvidersLookupId,
        Strings.companyId
      )[0]?.Title,
      link:
        "/auth/providers/details?id=" + selectedCapability[0]?.ProvidersLookupId
    },
    {
      linkName: "capabilities",
      link:
        "/auth/providers/details?type=capabilities&id=" +
        selectedCapability[0]?.ProvidersLookupId
    },
    {
      linkName: selectedCapability[0]?.Category,
      link: "/auth"
    }
  ];
  useEffect(() => {
    let filteredRes = {};
    selectedCapability.forEach(capability => {
      filteredRes[capability.Subcategory]
        ? filteredRes[capability.Subcategory].push(capability)
        : (filteredRes[capability.Subcategory] = [capability]);
    });
    setSubCategory(filteredRes);
  }, [selectedCapability]);

  return (
    <DictionaryContext.Consumer>
      {({ dictionary }) => {
        return (
          <AssetCategoryDetailsWrapper>
            <BreadCrumb links={breadCrumbData} closeModal={closeModal} />
            <CategoryTitle>{selectedCapability[0]?.Category}</CategoryTitle>
            <CategoryDescription>
              {
                fetchNameById(
                  capabilitiesCategory,
                  selectedCapability[0]?.Category,
                  dictionary?.title || Strings.title
                )[0]?.Description
              }
            </CategoryDescription>
            {subCategory &&
              Object.keys(subCategory).map((key, index) => (
                <Fragment key={index}>
                  <AssetSubCategoryDetails
                    key={index}
                    capabilitiesSubCategoryLookupIds={
                      capabilitiesSubCategoryLookupIds
                    }
                    subCategory={subCategory[key]}
                    searchedSubCategory={
                      searchedData?.filterType ===
                        (dictionary?.assetSubCategoryCapabilities ||
                          Strings.assetSubCategoryCapabilities) &&
                      searchedData?.subTitle === key &&
                      searchedData.subTitle
                    }
                    searchedAssetName={
                      searchedData?.filterType ===
                        (dictionary?.assetName || Strings.assetName) &&
                      searchedData?.title
                    }
                  />
                </Fragment>
              ))}
          </AssetCategoryDetailsWrapper>
        );
      }}
    </DictionaryContext.Consumer>
  );
};
AssetCategoryDetails.propTypes = {
  selectedCapability: PropTypes.array,
  capabilitiesCategory: PropTypes.array,
  closeModal: PropTypes.func,
  capabilitiesSubCategoryLookupIds: PropTypes.array,
  providers: PropTypes.array,
  searchedData: PropTypes.instanceOf(Object)
};
export default AssetCategoryDetails;
