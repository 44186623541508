import axios from "axios";

/**
 * Setting BaseUrl for accessing auth azure api
 */
const authBaseURL = `${process.env.AUTH_DEVELOPMENT_API_URL}` || "/";
/**
 * Setting BaseUrl for accessing azure api
 */
const baseURL = `${process.env.DEVELOPMENT_API_URL}` || "/";

// Instance for authentication
export const authInstance = axios.create({
  baseURL: authBaseURL,
  headers: {
    "Ocp-Apim-Subscription-Key": process.env.SUBSCRIPTION_KEY,
    "Access-Control-Allow-Origin": "*"
  }
});
/**
 * apiInstance used to make the get and post calls
 * @param {}
 * @example apiInstance.get() || apiInstance.post()
 */
export const apiInstance = axios.create({
  baseURL: baseURL,
  headers: {
    "Ocp-Apim-Subscription-Key": process.env.SUBSCRIPTION_KEY,
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json"
  }
});

/**
 * Adding authToken to the request header
 */
apiInstance.interceptors.request.use(config => {
  let token = "";
  if (window) {
    token = sessionStorage.getItem("apiOAuthToken");
  }
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});
