import CryptoJS from "crypto-js";

const hexKey = process.env.HEX_KEY;

export const encryptData = ({ data }) => {
  try {
    const cipherText = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      hexKey
    ).toString();
    return encodeURIComponent(cipherText);
  } catch (error) {
    console.debug(error);
  }
};

export const decryptData = ({ data }) => {
  try {
    let encryptedString = decodeURIComponent(data);
    const bytes = CryptoJS.AES.decrypt(encryptedString, hexKey);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  } catch (error) {
    console.debug(error);
  }
};
