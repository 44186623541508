// Sidebar Strings
const dacTitle = "DAC";
// Profile Strings
const signOut = "Sign out";
// Banner Strings
const explore = "Explore";
const providers = "Providers";
const provider = "Provider";
const featuredCaseStudy = "Featured case study";
const viewCaseStudy = "View case study";
// Card Strings
const more = "More";
const readMore = "Read more";
const popularProviders = "Popular Providers";
const assets = "Capabilities";
const newText = "New";
const popularAssets = "Popular capabilities";
const partnershipPotential = "Partnership Potential";
// TitleDropdown Strings
const filter = "Filter";
// ProviderDetails Page Tab Strings
const overview = "Overview";
const details = "details";
const caseStudies = "Case Studies";
const contact = "Contact";
const partnershipOpportunities = "Partnership Opportunities";
const valueProposition = "Value Proposition";
// Provider Details Summary Strings
const companyWebsite = "Company Website";
const inception = "Inception";
const industry = "Industry";
const categoryTitle = "Category";
const credential = "Credential";
const revenue = "Revenue Range";
const location = "Location";
const people = "People";
// Overview Tab Content Strings
const about = "About";
const purposeVision = "Purpose and Vision";
const uniqueness = "Uniqueness";
//Assets Tab Content Strings
const subAssets = "Sub Assets";
const subAsset = "Sub Asset";
//Contact Tab Strings
const name = "Name";
const email = "Email";
const businessEnquiry = "Business Enquiry";
const partnershipCollaboration = "Partnership & Collaboration";
// Contact Form Strings
const contactTitle = "Contact Relationship Manager";
const subject = "Subject";
const message = "Message";
const sendMessage = "Send Message";
const thankYou = "Thank you";
const successSentMessage = "Your message has been sent!";
const errorMessage = "Sorry! Your message could not be sent";
const tryAgain = "Please try again later";
// Pagination Strings
const next = "Next";
const prev = "Prev";
const pagination = "Pagination";
// FilterModal Strings
const category = "category";
const level = "level";
const capabilities = "capabilities";
const clear = "Clear";
const done = "Done";
const type = "Type";
const continent = "Continent";
const country = "Country";
const subCategory = "Sub-Category";
const providerCategory = "Provider Category";
const capabilitiesCategory = "Capabilities Category";
const noResultsFilteredTitle = "Too many filters applied!";
const noResultsFiltered =
  "Try removing the last few filters to view more results";
// CaseStudy Details Strings
const credits = "Credits";
const similarCaseStudy = "Similar Case Studies";
const by = "by";
const team = "Team";
const minRead = "min read";
//ExploreCategory strings
const ExploreCategoryTitle = "Explore by Category";
// QuickTourCard Strings
const skip = "Skip";
const finishedTour = "You have finished your tour!";
const startTour = "Start Tour";
const startExploring = "Start Exploring";
// LocalStorage Key String
const isFirstTime = "isFirstTime";
// NoResults Strings
const noResults = "No results found";
const noResultsDescription = "It seems your search does not match any results";
// CapabilitiesFilterModal String
const technology = "Technology";

//filterType providers search
const companyName = "company name";
const companyCategory = "company category";
const companyIndustry = "company industry";
const categoryAndCompany = "category and company";
const industryAndCompany = "industry and company";
const uniqueCategory = "uniqueCategory";
const uniqueIndustry = "uniqueIndustry";

//filterType capabilities search
const assetName = "asset name";
const assetCategoryProviders = "asset category providers";
const assetCategoryCapabilities = "asset category capabilities";
const assetSubCategoryProviders = "asset subcategory providers";
const assetSubCategoryCapabilities = "asset subcategory capabilities";

// Search result strings
const singleResult = "Search result";
const multiResults = "Search results";
const industryFilterText = "Other providers of same industry";
const categoryFilterText = "Others providers of same category";

// Key strings for filtering
const companyId = "id";
const title = "Title";

//no match string
const noMatch = "noMatch";

const Strings = {
  dacTitle,
  title,
  companyId,
  signOut,
  explore,
  featuredCaseStudy,
  viewCaseStudy,
  more,
  popularProviders,
  assets,
  providers,
  provider,
  filter,
  popularAssets,
  overview,
  caseStudies,
  contact,
  partnershipOpportunities,
  valueProposition,
  about,
  purposeVision,
  uniqueness,
  subAssets,
  subAsset,
  contactTitle,
  subject,
  message,
  sendMessage,
  details,
  next,
  prev,
  pagination,
  category,
  level,
  clear,
  done,
  credits,
  ExploreCategoryTitle,
  skip,
  finishedTour,
  startTour,
  similarCaseStudy,
  capabilities,
  readMore,
  partnershipPotential,
  newText,
  isFirstTime,
  noResults,
  noResultsDescription,
  companyWebsite,
  inception,
  industry,
  categoryTitle,
  credential,
  revenue,
  location,
  people,
  thankYou,
  successSentMessage,
  continent,
  country,
  subCategory,
  providerCategory,
  noResultsFiltered,
  startExploring,
  errorMessage,
  tryAgain,
  technology,
  singleResult,
  multiResults,
  industryFilterText,
  categoryFilterText,
  companyName,
  companyCategory,
  companyIndustry,
  categoryAndCompany,
  industryAndCompany,
  uniqueCategory,
  uniqueIndustry,
  assetName,
  assetCategoryProviders,
  assetCategoryCapabilities,
  assetSubCategoryProviders,
  assetSubCategoryCapabilities,
  type,
  capabilitiesCategory,
  noResultsFilteredTitle,
  name,
  email,
  businessEnquiry,
  partnershipCollaboration,
  by,
  team,
  minRead,
  noMatch
};

export default Strings;
