import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";

import Strings from "../../resources/strings";
import { DictionaryContext } from "../../providers/DictionaryContext";
import { OptionsListItem } from "./style";
import { postApiCall } from "../../services/api";
import { getUserId } from "../../utils/commonUtils";

/**
 * ProvidersListItem is used to render the providers list items
 * @param {array} providersList
 * @param {string} searchInput
 * @param {number} currentOption
 * @param {bool} isKeyPressed
 * @param {function} setFilteredOptions
 * @param {function} setSearchInput
 * @param {function} getData
 *  @param {function} getMaxSuggestionLimit
 *@example ProvidersListItem({providersList, searchInput, currentOption, isKeyPressed})
 */
const ProvidersListItem = props => {
  const {
    providersList,
    searchInput,
    currentOption,
    setFilteredOptions,
    setSearchInput,
    getData,
    getMaxSuggestionLimit,
    getListsDataProviders
  } = props;

  // to keep track of the row index in the providers list
  let rowIndex = -1;

  const [listItems, setListItems] = useState({
    companyName: [],
    uniqueCategory: [],
    uniqueIndustry: [],
    categoryAndCompany: [],
    industryAndCompany: []
  });

  const getRowData = (keyType, item) => {
    postApiCall.postSearchInput({
      userId: getUserId(),
      searchKeyWord: item.title,
      page: window.location.href?.split("/")?.slice(-1)[0],
      searchedTime: Date.now()
    });
    setFilteredOptions({
      filteredProviderOptions: [],
      filteredCapabilitiesOptions: []
    });
    setSearchInput(item.title);

    keyType === Strings.uniqueCategory || keyType === Strings.uniqueIndustry
      ? setSearchInput(item.title + " " + Strings.providers)
      : setSearchInput(item.title);

    getData(item);
  };

  useEffect(() => {
    const companyName = [];
    const category = [];
    const industry = [];
    const industryAndCompany = [];
    const categoryAndCompany = [];
    let allDataArray = [];
    let maxSuggestionLimit = 0;

    providersList?.forEach(listItem => {
      if (maxSuggestionLimit >= 10) return;
      if (listItem?.Title?.toLowerCase().includes(searchInput?.toLowerCase())) {
        companyName.push({
          key: Strings.providers,
          title: listItem?.Title,
          filterType: Strings.companyName
        });
        maxSuggestionLimit = maxSuggestionLimit + 1;
      }
      let categoryItem = listItem?.CategoryArray?.filter(listItem =>
        listItem.toLowerCase().includes(searchInput?.toLowerCase())
      )[0];
      if (categoryItem) {
        if (
          !category.find(eachCategory => eachCategory.title === categoryItem)
        ) {
          category.push({
            key: Strings.providers,
            title: categoryItem,
            filterType: Strings.companyCategory
          });
          maxSuggestionLimit = maxSuggestionLimit + 1;
        }

        categoryAndCompany.push({
          key: Strings.providers,
          title: categoryItem + " " + listItem?.Title,
          subTitle0: categoryItem,
          subTitle1: listItem?.Title,
          filterType: Strings.categoryAndCompany
        });
        maxSuggestionLimit = maxSuggestionLimit + 1;
      }
      if (
        listItem?.ComputedField_Industry?.toLowerCase().includes(
          searchInput?.toLowerCase()
        )
      ) {
        if (
          !industry.find(
            eachIndustry =>
              eachIndustry.title === listItem?.ComputedField_Industry
          )
        ) {
          industry.push({
            key: Strings.providers,
            title: listItem?.ComputedField_Industry,
            filterType: Strings.companyIndustry
          });
          maxSuggestionLimit = maxSuggestionLimit + 1;
        }

        industryAndCompany.push({
          key: Strings.providers,
          title: listItem?.ComputedField_Industry + " " + listItem?.Title,
          subTitle0: listItem?.ComputedField_Industry,
          subTitle1: listItem?.Title,
          filterType: Strings.industryAndCompany
        });
        maxSuggestionLimit = maxSuggestionLimit + 1;
      }
    });

    allDataArray.push(
      ...companyName,
      ...category,
      ...industry,
      ...categoryAndCompany,
      ...industryAndCompany
    );

    getListsDataProviders(allDataArray);

    setListItems({
      companyName: companyName,
      uniqueCategory: category,
      uniqueIndustry: industry,
      categoryAndCompany: categoryAndCompany,
      industryAndCompany: industryAndCompany
    });
    getMaxSuggestionLimit(maxSuggestionLimit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providersList, searchInput]);

  return (
    <DictionaryContext.Consumer>
      {({ dictionary }) => {
        return (
          <Fragment>
            {listItems &&
              Object.keys(listItems).map((keyType, index) => {
                return (
                  <Fragment key={keyType}>
                    {keyType &&
                      listItems[keyType].map((item, index1) => {
                        rowIndex++;
                        return (
                          <OptionsListItem
                            key={index + index1}
                            currentOption={currentOption}
                            currentRow={rowIndex}
                            onClick={() => getRowData(keyType, item)}
                            data-test="list-item">
                            {keyType ===
                              (dictionary?.uniqueCategory ||
                                Strings.uniqueCategory) ||
                            keyType ===
                              (dictionary?.uniqueIndustry ||
                                Strings.uniqueIndustry)
                              ? item.title +
                                " " +
                                (dictionary?.providers || Strings.providers)
                              : item.title}
                          </OptionsListItem>
                        );
                      })}
                  </Fragment>
                );
              })}
          </Fragment>
        );
      }}
    </DictionaryContext.Consumer>
  );
};

ProvidersListItem.propTypes = {
  providersList: PropTypes.arrayOf(Object),
  searchInput: PropTypes.string,
  currentOption: PropTypes.number,
  setFilteredOptions: PropTypes.func,
  setSearchInput: PropTypes.func,
  getData: PropTypes.func,
  getMaxSuggestionLimit: PropTypes.func,
  getListsDataProviders: PropTypes.func
};

export default ProvidersListItem;
