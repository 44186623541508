import styled from "styled-components";
import { CardDescription } from "../../styles/commonStyles";

export const EmployeeWrapper = styled.div`
  width: 100%;
  margin: 63px 0 24px 0;
  @media ${props => props.theme.breakPoints.mobileDevice} {
    margin: 40px 0 24px 0;
  }
  @media ${props => props.theme.breakPoints.tabDevice} {
    margin: 40px 0 24px 0;
  }
`;

export const EmployeeList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @media ${props => props.theme.breakPoints.tabDevice} {
    & > :nth-child(2n) {
      margin-left: 32px;
    }
  }
  @media ${props => props.theme.breakPoints.mobileDevice} {
    & > :nth-child(2n) {
      margin-left: 32px;
    }
  }
`;
export const EmployeeListItem = styled.div`
  display: flex;
  margin: 0 29px 2px 0;
  align-items: center;
  display: flex;
  @media ${props => props.theme.breakPoints.tabDevice} {
    min-width: calc(50% - 16px);
    max-width: calc(50% - 16px);
    margin-top: 16px;
    margin-right: 0px;
  }
  @media ${props => props.theme.breakPoints.mobileDevice} {
    min-width: calc(50% - 16px);
    max-width: calc(50% - 16px);
    margin-right: 0px;
    margin-top: 16px;
  }
`;

export const EmployeeDescription = styled.div`
  margin-left: 8px;
`;

export const EmpName = styled.p`
  color: ${props => props.theme.fontColor.activeBlack};
  font-size: ${props => props.theme.fontSize.secondary};
  font-weight: ${props => props.theme.fontWeight.bold};
  line-height: ${props => props.theme.lineHeight.small};
  margin-bottom: 0px;
`;

export const EmpDesignation = styled.p`
  color: ${props => props.theme.fontColor.activeBlack};
  font-size: ${props => props.theme.fontSize.tertiary};
  font-weight: ${props => props.theme.fontWeight.normal};
  line-height: ${props => props.theme.lineHeight.tertiary};
  margin: 4px 0 0;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  margin-left: auto;
`;

export const NavAndButtonWrapper = styled.div`
  margin-top: 21px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media ${props => props.theme.breakPoints.mobileDevice} {
    & > :first-child {
      min-width: fit-content;
    }
  }
  @media ${props => props.theme.breakPoints.tabDevice} {
    & > :first-child {
      min-width: fit-content;
    }
  }
`;

export const SummaryCardTagWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: ${props =>
    props.isDetails ? "32px 0px -10px 0px" : "9px 0px 20px 0px"};
  flex-wrap: wrap;
  width: 50%;
  @media ${props => props.theme.breakPoints.tabDevice} {
    width: 100%;
  }
  @media ${props => props.theme.breakPoints.mobileDevice} {
    width: 100%;
  }
`;

export const SummaryCardImageWrapper = styled.div`
  margin-bottom: ${props => (props.searchValue ? "24px" : "0px")};
`;

export const SummaryContainer = styled.div`
  width: 100%;
  border-radius: 16px;
  box-shadow: ${props => props.theme.boxShadow.container};
  background-color: #fff;
  padding: 52px 44px 27px 44px;
  box-sizing: border-box;
  @media ${props => props.theme.breakPoints.mobileDevice} {
    padding: 40px 20px 24px 20px;
  }
  @media ${props => props.theme.breakPoints.tabDevice} {
    padding: 40px 20px 24px 20px;
  }
`;

export const EmployeeImage = styled.img`
  object-fit: cover;
  width: 40px;
  height: 40px;
  border-radius: 20px;
`;

export const CompanyDetailsWrapper = styled.div`
  width: 100%;
  margin: 20px 0 22px;
`;

export const CompanyDetailsList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @media ${props => props.theme.breakPoints.tabDevice} {
    & > :nth-child(2n) {
      margin-left: 32px;
    }
  }
  @media ${props => props.theme.breakPoints.mobileDevice} {
    & > :nth-child(2n) {
      margin-left: 32px;
    }
  }
`;

export const CompanyDetailsListItem = styled.div`
  margin: 0 27px 2px 0;
  @media ${props => props.theme.breakPoints.mobileDevice} {
    min-width: Calc(50% - 16px);
    max-width: Calc(50% - 16px);
    margin-top: 16px;
    margin-right: 0;
  }
  @media ${props => props.theme.breakPoints.tabDevice} {
    min-width: Calc(50% - 16px);
    max-width: Calc(50% - 16px);
    margin-top: 16px;
    margin-right: 0;
  }
`;

export const CompanyHeader = styled.p`
  font-size: ${props => props.theme.fontSize.tertiary};
  font-weight: ${props => props.theme.fontWeight.normal};
  color: ${props => props.theme.fontColor.grey};
  line-height: ${props => props.theme.lineHeight.description};
  padding-top: 4px;
  margin-bottom: 0px;
`;

export const CompanyDescription = styled.p`
  font-size: ${props => props.theme.fontSize.secondary};
  font-weight: ${props => props.theme.fontWeight.bold};
  color: ${props => props.theme.fontColor.activeBlack};
  line-height: ${props => props.theme.lineHeight.default};
  max-width: ${props =>
    (props.isIndustry || props.isCategory || props.isLocation) && "200px"};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const SummaryDescriptionWrapper = styled.div`
  margin-top: 24px;
`;

export const SummaryDescription = styled(CardDescription)`
  display: flex;
  justify-content: flex-start;
`;

export const SummaryWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media ${props => props.theme.breakPoints.tabDevice} {
    flex-direction: column;
    align-items: flex-end;
  }
  @media ${props => props.theme.breakPoints.mobileDevice} {
    flex-direction: column;
    align-items: flex-end;
  }
`;
