import React, { useState } from "react";

import Menu from "./Menu";
import CustomLink from "../CommonComponents/Link/Link";
import {
  SidebarWrapper,
  LogoWrapper,
  Wrapper,
  BrandLogoWrapper
} from "./style";
import { getRefactoredImageUrl } from "../../utils/commonUtils";
import { DictionaryContext } from "../../providers/DictionaryContext";

/**
 * Sidebar component is a part of layout
 * @example
 * Sidebar()
 */
const Sidebar = () => {
  const [clicked, setClicked] = useState(false);

  return (
    <DictionaryContext.Consumer>
      {({ dictionary }) => {
        return (
          <SidebarWrapper>
            <CustomLink to="/auth/explore">
              <Wrapper>
                <LogoWrapper
                  src={getRefactoredImageUrl(dictionary?.LogoMobile)}
                  alt="sbg-logo"
                />
                <BrandLogoWrapper
                  src={getRefactoredImageUrl(dictionary?.BrandLogo)}
                  alt="brand-logo"></BrandLogoWrapper>
              </Wrapper>
            </CustomLink>
            <Menu clicked={() => setClicked(true)} click={clicked} />
          </SidebarWrapper>
        );
      }}
    </DictionaryContext.Consumer>
  );
};

export default Sidebar;
