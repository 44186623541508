import styled, { keyframes } from "styled-components";

export const Anchor = styled.a`
  margin: 0 4px 0 0;
  text-decoration: none;
  font-size: ${props => props.theme.fontSize.secondary};
  font-weight: ${props => props.theme.fontWeight.bold};
  line-height: ${props => props.theme.lineHeight.normal};
  color: ${props => props.textcolor || props.theme.fontColor.white};
  cursor: pointer;
  display: flex;
  align-items: center;
  & > img {
    padding-top: 2px;
  }
  &:hover {
    color: ${props => props.textcolor || props.theme.fontColor.white};
  }
`;

export const Button = styled.button`
  background-color: ${props =>
    props.primary ? props.theme.backgroundColor.activeBtn : "transparent"};
  border-radius: 8px;
  border: ${props =>
    props.primary
      ? `solid 1px ${props.theme.border.royalBlue}`
      : `solid 1px ${props.theme.border.royalBlue}`};

  color: ${props =>
    props.primary
      ? props.theme.fontColor.white
      : props.theme.fontColor.linkColor};
  font-size: ${({ theme }) => theme.fontSize.primary};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  line-height: 1.5;
  padding: 14px;
  min-width: 160px;
  &:hover {
    background-color: ${props =>
      props.primary && props.theme.backgroundColor.hover};
    border: ${props => `solid 1px ${props.theme.border.hover}`};
    color: ${props =>
      props.primary
        ? props.theme.fontColor.white
        : props.theme.fontColor.hover};
  }
  :disabled {
    border: ${props =>
      props.primary ? "none" : `solid 1px ${props.theme.border.btnDisabled}`};
    background-color: ${props =>
      props.primary ? props.theme.backgroundColor.disabled : "transparent"};
    cursor: not-allowed;
    color: ${props =>
      props.primary
        ? props.theme.fontColor.disabled
        : props.theme.fontColor.btnDisabled};
  }
`;

export const OnlyMobileView = styled.div`
  display: none;
  @media ${props => props.theme.breakPoints.mobileDevice} {
    display: block;
  }
  @media ${props => props.theme.breakPoints.tabDevice} {
    display: block;
  }
`;

export const PageContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  max-width: 1049px;
  padding: 0 0 90px 79px;
  margin-top: 24px;
  min-height: 100vh;
  @media ${props => props.theme.breakPoints.mobileDevice} {
    overflow-y: auto;
    padding: 0 20px 50px;
    margin-top: 0px;
  }
  @media ${props => props.theme.breakPoints.tabDevice} {
    overflow-y: auto;
    padding: 0 20px 50px;
    margin-top: 0px;
  }
  @media ${props => props.theme.breakPoints.smallDesktopDevice} {
    padding: 0 98px 90px 15px;
    margin-top: 0px;
  }
  @media ${props => props.theme.breakPoints.mediumDesktopDevice} {
    padding: 0 25px 90px 30px;
  }
  @media ${props => props.theme.breakPoints.largeDesktopDevice} {
    max-width: unset;
    padding-right: 162px;
  }
`;

export const OnlyWebView = styled.div`
  display: block;
  @media ${props => props.theme.breakPoints.mobileDevice} {
    display: none;
  }
  @media ${props => props.theme.breakPoints.tabDevice} {
    display: none;
  }
`;

export const BannerTitle = styled.span`
  margin: 0;
  display: inline-block;
  font-size: ${props => props.theme.fontSize.sdheading};
  font-weight: ${props => props.theme.fontWeight.bold};
  color: ${props => props.theme.fontColor.activeBlack};
  @media ${props => props.theme.breakPoints.mobileDevice} {
    font-size: ${props => props.theme.fontSize.heading};
  }
`;

// common styled components for card
export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 310px;
  margin: 20px 21px 0 0;
  padding: 0 0 21px;
  border-radius: 12px;
  box-shadow: ${props => props.theme.shadow.card};
  background-color: ${props => props.theme.backgroundColor.white};
  @media ${props => props.theme.breakPoints.xlMediumDesktopDevice} {
    &:nth-child(3n) {
      margin-right: 0;
    }
  }
  @media screen and (min-width: 1700px) {
    &:nth-child(3n) {
      margin-right: 21px;
    }
  }
  @media ${props => props.theme.breakPoints.mobileDevice} {
    width: 100%;
    margin-right: 0;
  }
`;

export const CardHeadingWrapper = styled.div`
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  display: flex;
  align-items: center;
  background-color: ${props =>
    props.headingBgColor
      ? props.theme.backgroundColor.cardBgColor
      : props.theme.backgroundColor.white};
`;

export const CardImageWrapper = styled.div`
  width: 64px;
  height: 64px;
  box-sizing: border-box;
  background-color: ${props => props.theme.backgroundColor.white};
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${props => props.theme.breakPoints.mobileDevice} {
    width: 49px;
    height: 49px;
  }
`;

export const CardImage = styled.img`
  object-fit: cover;
  width: 44px;
  height: 44px;
  @media ${props => props.theme.breakPoints.mobileDevice} {
    width: 34px;
    height: 34px;
  }
`;

export const CardTitle = styled.h3`
  font-size: ${props => props.theme.fontSize.subHeading};
  font-weight: ${props => props.theme.fontWeight.bold};
  color: ${props => props.theme.fontColor.activeBlack};
  line-height: ${props => props.theme.lineHeight.primary};
  margin-bottom: 0;
  width: ${props => (props.isFixWidth ? props.isFixWidth : "unset")};
  @media ${props => props.theme.breakPoints.mobileDevice} {
    max-width: 160px;
  }
`;

export const CardContentWrapper = styled.div`
  width: 100%;
  padding: 19px 20px 0 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  @media ${props => props.theme.breakPoints.mobileDevice} {
    padding: 15px 20px 0 20px;
  }
`;

export const CardDescription = styled.div`
  color: ${props => props.theme.fontColor.inactiveBlack};
  font-size: ${props => props.theme.fontSize.medium};
  line-height: ${props => props.theme.lineHeight.default};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
  box-sizing: border-box;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  @-moz-document url-prefix() {
    -webkit-box-align: start;
  }
`;

export const CardDetails = styled.div`
  display: flex;
  margin-top: 13px;
  @media ${props => props.theme.breakPoints.mobileDevice} {
    margin-top: 0;
  }
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CardDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  &:first-child {
    min-width: fit-content;
  }
  &:last-child {
    margin-left: 26px;
  }
`;

export const CardSubTitle = styled.div`
  font-size: ${props => props.theme.fontSize.medium};
  color: ${props => props.theme.fontColor.inactiveBlack};
  line-height: ${props => props.theme.lineHeight.secondary};
  margin-bottom: 4px;
  font-weight: ${props => props.theme.fontWeight.medium};
`;

export const CardSubDescription = styled.div`
  color: ${props => props.theme.fontColor.activeBlack};
  font-size: ${props => props.theme.fontSize.secondary};
  font-weight: ${props => props.theme.fontWeight.bold};
  line-height: ${props => props.theme.lineHeight.secondary};
  margin-bottom: 10px;
`;

export const CardLinkWrapper = styled.div`
  margin-top: 10px;
`;

export const CardNewTag = styled.div`
  font-size: ${props => props.theme.fontSize.extrasmall};
  line-height: ${props => props.theme.lineHeight.normal};
  color: ${props => props.theme.fontColor.primary};
  font-weight: ${props => props.theme.fontWeight.medium};
  background-color: ${props => props.theme.backgroundColor.pacificBlue};
  padding: 2px 6px;
  text-align: center;
  border-radius: 6px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  display: flex;
  margin: 0 0 5px auto;
`;

export const CardTagWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 9px 0 20px;
`;

export const CardTags = styled.div`
  margin: 0 4px 4px 0;
  border-radius: 4px;
  padding: 6px 7px 6px 8px;
  background-color: ${props => props.theme.backgroundColor.primary0};
  font-size: ${props => props.theme.fontSize.secondary};
  font-weight: ${props => props.theme.fontWeight.bold};
  line-height: ${props => props.theme.lineHeight.normal};
  text-align: center;
  color: ${props => props.theme.fontColor.inactiveBlack};
`;

// common styled components for Tab Content
export const TabContentTitle = styled.h2`
  margin-bottom: 16px;
  font-size: ${props => props.theme.fontSize.subHeading};
  font-weight: ${props => props.theme.fontWeight.bold};
  color: ${props => props.theme.fontColor.activeBlack};
  line-height: ${props => props.theme.lineHeight.primary};
`;

export const TabContentParagraph = styled.p`
  margin-bottom: 30px;
  color: ${props => props.theme.fontColor.activeBlack};
  font-size: ${props => props.theme.fontSize.primary};
  line-height: ${props => props.theme.lineHeight.content};
  @media ${props => props.theme.breakPoints.mobileDevice} {
    margin-bottom: 26px;
  }
  @media ${props => props.theme.breakPoints.tabDevice} {
    margin-bottom: 26px;
  }
`;

export const TertiaryButton = styled.button`
  padding: 10px 26px 11px;
  border-radius: 26px;
  font-size: ${props => props.theme.fontSize.secondary};
  font-weight: ${props => props.theme.fontWeight.bold};
  text-align: center;
  background-color: ${props => props.theme.backgroundColor.blue};
  color: ${props => props.theme.fontColor.white};
  border: none;
  @media ${props => props.theme.breakPoints.mobileDevice} {
    padding: 10px 32px 11px;
    margin: 16px 0;
  }
`;
// Card as a button
export const CardButton = styled(Button)`
  border: none;
  padding: 0;
  background-color: ${props => props.theme.backgroundColor.white};
  text-align: left;
  :hover {
    padding: 0;
    border: none;
  }
  @media ${props => props.theme.breakPoints.mobileDevice} {
    width: 100%;
  }
`;

// Common styles for Table
export const TableWrapper = styled.table`
  width: 100%;
  overflow-x: auto;
  margin-bottom: 38px;
  @media ${props => props.theme.breakPoints.mobileDevice} {
    margin-bottom: 51px;
  }
  @media ${props => props.theme.breakPoints.tabDevice} {
    margin-bottom: 51px;
  }
`;

export const TableHeading = styled.thead``;

export const TableTitle = styled.th`
  padding: 15px 6px 18px 20px;
  min-width: 173px;
  font-size: ${props => props.theme.fontSize.primary};
  font-weight: ${props => props.theme.fontWeight.bold};
  color: ${props => props.theme.fontColor.activeBlack};
  :first-child {
    padding-left: 10px;
  }
  @media ${props => props.theme.breakPoints.tabDevice} {
    font-size: ${props => props.theme.fontSize.secondary};
  }
  @media ${props => props.theme.breakPoints.mobileDevice} {
    font-size: ${props => props.theme.fontSize.secondary};
  }
`;

export const TableBody = styled.tbody``;

export const TableRow = styled.tr`
  th,
  td {
    border: 1px solid ${props => props.theme.border.table};
  }
  th:first-child,
  td:first-child {
    border-left: none;
  }
  td:first-child {
    font-weight: ${props => props.theme.fontWeight.bold};
    padding-left: 10px;
  }
  th:last-child,
  td:last-child {
    border-right: none;
  }
`;

export const TableData = styled.td`
  font-size: ${props => props.theme.fontSize.secondary};
  line-height: ${props => props.theme.lineHeight.content};
  padding: 20px 17px 13px 20px;
  min-width: 173px;
  text-align: left;
  vertical-align: text-top;
  @media ${props => props.theme.breakPoints.mobileDevice} {
    min-width: 122px;
  }
`;
export const AssetDescription = styled.div`
  font-size: ${props => props.theme.fontSize.primary};
  font-weight: ${props => props.theme.fontWeight.normal};
  color: ${props => props.theme.fontColor.activeBlack};
  line-height: ${props => props.theme.lineHeight.content};
  text-align: left;
`;

export const AssetTitle = styled.div`
  font-size: ${props => props.theme.fontSize.primary};
  font-weight: ${props => props.theme.fontWeight.bold};
  color: ${props => props.theme.fontColor.activeBlack};
  line-height: ${props => props.theme.lineHeight.primary};
`;

export const FeaturedCaseStudy = styled.p`
  font-size: ${props => props.theme.fontSize.tertiary};
  font-weight: ${props => props.theme.fontWeight.bold};
  line-height: ${props => props.theme.lineHeight.normal};
  color: ${props =>
    props.background
      ? props.theme.fontColor.manatee
      : props.theme.fontColor.white};
  margin: 8px 0 0;
`;
//Styling for modal close button
export const CloseButton = styled.button`
  width: 32px;
  height: 32px;
  z-index: 999;
  background-color: ${props => props.theme.backgroundColor.primary0};
  border-radius: 8px;
  position: ${props => (props.filter ? "absolute" : "sticky")};
  float: ${props => !props.filter && "right"};
  top: ${props => (props.filter ? "25px" : "5px")};
  right: ${props => (props.filter ? "41px" : "0")};
  @media ${props => props.theme.breakPoints.mediumDesktopDevice} {
    top: ${props => props.filter && "5px"};
  }
  @media ${props => props.theme.breakPoints.smallDesktopDevice} {
    top: ${props => props.filter && "5px"};
  }
  @media ${props => props.theme.breakPoints.tabDevice} {
    top: ${props => props.filter && "5px"};
  }
  @media ${props => props.theme.breakPoints.mobileDevice} {
    display: ${props => props.filter && "none"};
  }
`;

// fadeAnimation for modal
export const fadeInAnimation = keyframes`
 0% {
     transform:translateY(10px);
     opacity:0;
    }
 100%{
    transform:translateY(-12px);
    opacity:1;
    }
`;

//Buttons Wrapper for filter
export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  margin-left: auto;
  margin-right: 21px;
  & > * {
    min-width: 190px;
    max-height: 52px;
  }
  & > :last-child {
    margin-left: 10px;
  }
  @media ${props => props.theme.breakPoints.mobileDevice} {
    display: flex;
    margin-right: 0px;
    & > * {
      min-width: 181px;
    }
  }
  @media ${props => props.theme.breakPoints.smallMobileDevice} {
    display: flex;
    & > * {
      min-width: 160px;
    }
  }
`;

export const CheckBox = styled.input.attrs({
  type: "checkbox",
  readOnly: true
})``;

export const DetailsInformationTitle = styled.h2`
  margin-bottom: 16px;
  font-size: ${props => props.theme.fontSize.secondaryHeading};
  font-weight: ${props => props.theme.fontWeight.bold};
  color: ${props => props.theme.fontColor.activeBlack};
  line-height: ${props => props.theme.lineHeight.primary};
  @media ${props => props.theme.breakPoints.mobileDevice} {
    margin-bottom: 14px;
  }
  @media ${props => props.theme.breakPoints.tabDevice} {
    margin-bottom: 14px;
  }
`;
