import styled from "styled-components";

import { CardWrapper } from "../../../styles/commonStyles";

export const GridContainer = styled.div`
  width: 100%;
`;

export const GridContainerTitle = styled.div`
  margin: 0;
  opacity: 0.8;
  font-size: ${props => props.theme.fontSize.quaternary};
  font-weight: ${props => props.theme.fontWeight.bolder};
  line-height: ${props => props.theme.lineHeight.medium};
  color: ${props => props.theme.fontColor.activeBlack};
`;

export const GridCustomLink = styled.div`
  margin: 24px 0 16px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-size: ${props => props.theme.fontSize.tertiary};
`;

export const GridCustomLinkMore = styled.span`
  display: inline-block;
  font-weight: ${props => props.theme.fontWeight.bolder};
  line-height: ${props => props.theme.lineHeight.medium};
  color: ${props => props.theme.fontColor.inActiveBlack};
`;

export const GridRedirectText = styled.p`
  font-weight: ${props => props.theme.fontWeight.bolder};
  line-height: ${props => props.theme.lineHeight.medium};
  color: ${props => props.theme.fontColor.linkColor};
  cursor: pointer;
  margin: 0 0 0 5px;
  display: flex;
  align-items: center;
`;

export const GridLayoutContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  @media ${props => props.theme.breakPoints.mobileDevice} {
    overflow-x: ${props => (props.isScroll ? "scroll" : "none")};
    ::-webkit-scrollbar {
      display: none;
    }
    /* Hide scrollbar for IE, Edge add Firefox  */
    -ms-overflow-style: none;
    /* Firefox */
    scrollbar-width: none;
    display: ${props => (props.isScroll ? "-webkit-box" : "flex")};
    flex-wrap: ${props => (props.isScroll ? "unset" : "wrap")};
    ${CardWrapper} {
      width: ${props => props.isScroll && "95%"};
      flex-wrap: ${props => props.isScroll && "nowrap"};
      margin-right: ${props => (props.isScroll ? "10px" : "0")};
    }
  }
`;

export const CardGripWrapper = styled.div`
  margin-bottom: 32px;
`;
