import styled from "styled-components";

export const LayoutWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
`;

export const ContentWrapper = styled.div`
  width: calc(100% - 220px);
  background-color: ${props => props.theme.backgroundColor.tagBgColor};
  @media ${props => props.theme.breakPoints.tabDevice} {
    width: 100%;
  }
  @media ${props => props.theme.breakPoints.mobileDevice} {
    width: 100%;
  }
`;

export const Main = styled.main`
  width: 100%;
  overflow-y: auto;
  @media ${props => props.theme.breakPoints.tabDevice} {
    min-height: 68vh;
    margin-top: ${props => (!props.showSearch ? "124px" : "180px")};
  }
  @media ${props => props.theme.breakPoints.mobileDevice} {
    min-height: 68vh;
    margin-top: ${props => (!props.showSearch ? "124px" : "180px")};
  }
`;
