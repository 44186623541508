import React from "react";
import PropTypes from "prop-types";

import ProvidersResult from "./ProvidersResult";
import CapabilitiesResult from "./CapabilitiesResult";
import { SearchResultsWrapper } from "./style";

/**
 * SearchResults is used to render the ProvidersResult or Capabilities result
 * @param {object} searchedData
 * @example
 * SearchResults({searchedData})
 */
const SearchResults = props => {
  const { searchedData } = props;
  return (
    <SearchResultsWrapper>
      {searchedData.key === "Providers" ? (
        <ProvidersResult searchedData={searchedData} />
      ) : (
        <CapabilitiesResult searchedData={searchedData} />
      )}
    </SearchResultsWrapper>
  );
};

SearchResults.propTypes = {
  searchedData: PropTypes.instanceOf(Object)
};

export default SearchResults;
