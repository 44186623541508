import CloseBlack from "../images/close-black.svg";
import CloseWhite from "../images/close-white.svg";
import DownArrow from "../images/down-arrow.svg";
import RightArrow from "../images/right-arrow.svg";
import WhiteRightArrow from "../images/white-right-arrow.svg";
import GreyRightArrow from "../images/rightGreyArrow.svg";
import SearchSvg from "../images/search-icon.svg";
import DownloadImg from "../images/download.png";
import RedirectImage from "../images/redirect.png";
import ClearTextSearch from "../images/clear-text-search.svg";
import SuccessImg from "../images/success.svg";
import NoResultsImg from "../images/noResults.svg";
import ErrorIcon from "../images/error-icon.svg";
import FilterMobile from "../images/filter-mobile.svg";
import NoFilterResult from "../images/filterResult.svg";

const Images = {
  CloseBlack,
  CloseWhite,
  DownArrow,
  RightArrow,
  WhiteRightArrow,
  GreyRightArrow,
  SearchSvg,
  DownloadImg,
  RedirectImage,
  ClearTextSearch,
  SuccessImg,
  NoResultsImg,
  ErrorIcon,
  FilterMobile,
  NoFilterResult
};
export default Images;
