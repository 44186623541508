import styled, { css } from "styled-components";

export const SidebarWrapper = styled.div`
  height: 100vh;
  width: 220px;
  position: sticky;
  top: 0;
  left: 0;
  padding: 40px 32px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: ${props => props.theme.backgroundColor.white};
  @media ${props => props.theme.breakPoints.tabDevice} {
    display: none;
  }
  @media ${props => props.theme.breakPoints.mobileDevice} {
    display: none;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
`;

export const LogoWrapper = styled.img`
  width: 40px;
  height: 48px;
  margin-bottom: 40px;
  object-fit: contain;
`;

export const BrandLogoWrapper = styled.img`
  max-width: 148px;
  max-height: 124px;
  margin-bottom: 20px;
  @media ${props => props.theme.breakPoints.largeDesktopDevice} {
    margin-bottom: 20px;
  }
  @media ${props => props.theme.breakPoints.mediumDesktopDevice} {
    margin-bottom: 41px;
    display: none;
  }
  @media ${props => props.theme.breakPoints.smallDesktopDevice} {
    margin-bottom: 41px;
    display: none;
  }
`;

export const Title = styled.span`
  margin: 7px 0 12px 8px;
  background-image: linear-gradient(166deg, #0033a1 16%, #00a1e0 140%);
  font-size: ${props => props.theme.fontSize.subHeading};
  font-weight: ${props => props.theme.fontWeight.bold};
  line-height: ${props => props.theme.lineHeight.content};
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  @media ${props => props.theme.breakPoints.tabDevice} {
    display: none;
  }
  @media ${props => props.theme.breakPoints.mobileDevice} {
    display: none;
  }
`;

export const Navbar = styled.nav`
  list-style-type: none;
  ${props =>
    props.mobileView &&
    css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    `}
`;

export const NavItem = styled.li`
  margin: 20px 8px;
  display: flex;
  align-items: center;
  ${props =>
    props.mobileView &&
    css`
      margin: 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
    `}
`;

export const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  @media ${props => props.theme.breakPoints.tabDevice} {
    flex-direction: column;
  }
  @media ${props => props.theme.breakPoints.mobileDevice} {
    flex-direction: column;
  }
`;

export const NavImage = styled.img`
  ${props =>
    props.active &&
    css`
      filter: drop-shadow(0px 6px 4px rgba(65, 81, 217, 0.4));
    `};
  margin-right: 15px;
  width: 28px;
  height: 28px;
  object-fit: cover;
  @media ${props => props.theme.breakPoints.tabDevice} {
    margin-right: 0;
  }
  @media ${props => props.theme.breakPoints.mobileDevice} {
    margin-right: 0;
    width: ${props =>
      props.index === 0 ? "22px" : props.index === 1 ? "26px" : "17px"};
    height: 22px;
  }
`;

export const NavText = styled.span`
  font-size: ${props => props.theme.fontSize.secondary};
  font-weight: ${props => props.theme.fontWeight.bold};
  color: ${props =>
    props.active
      ? props.theme.fontColor.activeBlack
      : props.theme.fontColor.inactiveBlack};
  :hover {
    color: ${props => props.theme.fontColor.activeBlack};
  }
  @media ${props => props.theme.breakPoints.mobileDevice} {
    font-size: ${props => props.theme.fontSize.tertiary};
  }
`;
