import React from "react";
import PropTypes from "prop-types";

import ImageCard from "../ImageCard/ImageCard";
import Images from "../../../resources/images";
import { IconTextBtn } from "./style";

/**
 * Custom Icon text CTA to handle the custom onclick
 * @param {string} onClick
 * @param {string} linkText text to show in the link
 * @param {string} textcolor link text color
 */

const IconTextButton = ({ onClick, linkText, textcolor }) => {
  return (
    <IconTextBtn onClick={onClick} textcolor={textcolor}>
      {linkText}
      <ImageCard
        img={textcolor ? Images.RightArrow : Images.WhiteRightArrow}
        width="16px"
        height="16px"
        mobWidth="16px"
        mobHeight="16px"
        alt="arrow"></ImageCard>
    </IconTextBtn>
  );
};

IconTextButton.propTypes = {
  onClick: PropTypes.func,
  linkText: PropTypes.string,
  textcolor: PropTypes.string
};

export default IconTextButton;
