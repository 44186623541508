import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  Fragment
} from "react";
import PropTypes from "prop-types";

import PageModal from "../PageModal/PageModal";
import AssetCategoryDetails from "../Pages/CapabilitiesCategoryDetails/CapabilitiesCategoryDetails";
import CardGrid from "../CommonComponents/CardGrid/CardGrid";
import AssetCard from "../Cards/CapabilitiesCard/CapabilitiesCard";
import Strings from "../../resources/strings";
import Pagination from "../Pagination/Pagination";
import { getUserId } from "../../utils/commonUtils";
import { StateContext } from "../../providers/StateContext";
import { DictionaryContext } from "../../providers/DictionaryContext";
import { ResultsText } from "./style";
import { postApiCall } from "../../services/api";

/**
 * CapabilitiesFilterResults is used to render the results of capabilities filter
 * @param {array} filterResult
 * @param {bool} isCategory
 * @param {Object} searchedData
 * @returns
 */
const CapabilitiesFilterResults = props => {
  const { filterResult, isCategory, searchedData } = props;

  const state = useContext(StateContext);
  const { capabilitiesCategory, providers, lookups } = state;
  const capabilitiesCategoryMaster = lookups.capabilitiesCategory;
  const capabilitiesSubCategoryLookupIds =
    lookups.capabilitiesSubCategoryLookupId;

  const [selectedCapability, setSelectedCapability] = useState(null);

  const modalRef = useRef(null);
  const [showModal, setShowModal] = useState(false);

  const handleModal = selectedCapability => {
    selectedCapability && setSelectedCapability(selectedCapability);
    setShowModal(true);
  };
  const closeModal = () => {
    modalRef.current.classList.remove("fade");
    setShowModal(false);
  };

  const pageConfig = {
    pageSize: 9,
    pageLimit: 6
  };
  const pagePrevState = window.sessionStorage.getItem(Strings.capabilities);
  const [currentPage, setCurrentPage] = useState(
    pagePrevState?.currentPage || 1
  );
  const [currentPageSeries, setCurrentPageSeries] = useState(
    pagePrevState?.currentPageSeries || 1
  );

  const [filteredCapabilities, setFilteredCapabilities] = useState(null);

  useEffect(() => {
    var filterArray = filterResult.slice(
      currentPage * pageConfig.pageSize - pageConfig.pageSize,
      currentPage * pageConfig.pageSize
    );
    setFilteredCapabilities(filterArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterResult]);

  /**
   * updatePageData on filter logic or pagination
   * @param {Array} providerData
   * @param {Number} currentPage
   * @param {Number} currentPageSeries
   */
  const updatePageData = (filterResult, currentPage, currentPageSeries) => {
    setCurrentPageSeries(currentPageSeries);
    setCurrentPage(currentPage);
    setFilteredCapabilities(filterResult);
    window.sessionStorage.setItem(
      Strings.capabilities,
      JSON.stringify({
        currentPage: currentPage,
        currentPageSeries: currentPageSeries
      })
    );
  };

  return (
    <Fragment>
      <DictionaryContext.Consumer>
        {({ dictionary }) => {
          return (
            <ResultsText>
              {filterResult.length === 1
                ? filterResult.length +
                  " " +
                  (dictionary?.singleResult || Strings.singleResult)
                : filterResult.length +
                  " " +
                  (dictionary?.multiResults || Strings.multiResults)}
            </ResultsText>
          );
        }}
      </DictionaryContext.Consumer>
      <CardGrid noTitle={true}>
        {filteredCapabilities &&
          filteredCapabilities?.map((data, index) => {
            return (
              <AssetCard
                key={index}
                providers={providers}
                capabilitiesCategory={capabilitiesCategoryMaster}
                capabilities={data}
                handleModal={() => {
                  postApiCall.postPopularCards({
                    type: Strings.capabilities,
                    createdTime: data?.CreatedTime,
                    userId: getUserId(),
                    clickedTime: Date.now(),
                    listId: data?.listId,
                    itemId: data?.id
                  });
                  handleModal(
                    capabilitiesCategory[data?.ProvidersLookupId][
                      data?.Category
                    ]
                  );
                }}
                filtered={data?.Subcategory && !isCategory}
                data-test="asset-card"></AssetCard>
            );
          })}
      </CardGrid>
      <Pagination
        onPageChange={updatePageData}
        currentPage={currentPage}
        pageData={filterResult}
        pageSize={pageConfig.pageSize}
        pageLimit={pageConfig.pageLimit}
        currentPageSeries={currentPageSeries}
        data-test="pagination"></Pagination>
      <PageModal
        handleModal={handleModal}
        showModal={showModal}
        closeModal={closeModal}
        modalRef={modalRef}>
        <AssetCategoryDetails
          selectedCapability={selectedCapability}
          closeModal={closeModal}
          capabilitiesCategory={capabilitiesCategoryMaster}
          providers={providers}
          capabilitiesSubCategoryLookupIds={Object.values(
            capabilitiesSubCategoryLookupIds
          )}
          searchedData={searchedData}
        />
      </PageModal>
    </Fragment>
  );
};

CapabilitiesFilterResults.propTypes = {
  filterResult: PropTypes.arrayOf(Object),
  isCategory: PropTypes.bool,
  searchedData: PropTypes.instanceOf(Object)
};

export default CapabilitiesFilterResults;
