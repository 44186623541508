import React, { useEffect } from "react";
import PropTypes from "prop-types";

import ImageCard from "../../CommonComponents/ImageCard/ImageCard";
import Accordion from "../../Accordion/Accordion";
import {
  decodeHTMLMarkup,
  fetchNameById,
  getCapabilitiesId,
  getRefactoredImageUrl
} from "../../../utils/commonUtils";
import RichText from "../../CommonComponents/RichText/RichText";
import { DictionaryContext } from "../../../providers/DictionaryContext";
import {
  SubCategoryWrapper,
  TitleWrapper,
  SubCategoryTitle,
  SubCategoryDescription,
  AssetWrapper
} from "./style";
import Strings from "../../../resources/strings";

/**
 * AssetSubCategoryDetails includes sub-category title,description and Accordion
 * @param {Array} subCategory
 * @param {Array} capabilitiesSubCategoryLookupIds
 * @param {string} searchedSubCategory
 *  @param {string} searchedAssetName
 * @returns
 */
const AssetSubCategoryDetails = props => {
  const {
    subCategory,
    capabilitiesSubCategoryLookupIds,
    searchedSubCategory,
    searchedAssetName
  } = props;

  useEffect(() => {
    searchedSubCategory?.length &&
      document.querySelector(`#${getCapabilitiesId(searchedSubCategory)}`) &&
      document
        .querySelector(`#${getCapabilitiesId(searchedSubCategory)}`)
        .scrollIntoView({ behavior: "smooth" });
  }, [searchedSubCategory]);
  return (
    subCategory && (
      <DictionaryContext.Consumer>
        {({ dictionary }) => {
          return (
            <SubCategoryWrapper>
              <TitleWrapper
                id={
                  searchedSubCategory
                    ? getCapabilitiesId(searchedSubCategory)
                    : ""
                }>
                <ImageCard
                  img={getRefactoredImageUrl(dictionary?.AssetIcon)}
                  width="30px"
                  height="30px"
                  mobWidth="30px"
                  mobHeight="30px"
                />
                <SubCategoryTitle>
                  {subCategory[0]?.Subcategory}
                </SubCategoryTitle>
              </TitleWrapper>
              <SubCategoryDescription>
                <RichText>
                  {
                    fetchNameById(
                      capabilitiesSubCategoryLookupIds,
                      subCategory[0]?.Subcategory,
                      dictionary?.title || Strings.title
                    )[0]?.Description
                  }
                </RichText>
              </SubCategoryDescription>
              <AssetWrapper>
                {subCategory.map((data, index) => {
                  const descData = decodeHTMLMarkup(data?.Description);
                  return (
                    <Accordion
                      isSelected={true}
                      title={data?.Title}
                      key={index}
                      description={descData}
                      accordionAssetName={
                        data?.Title === searchedAssetName && searchedAssetName
                      }
                    />
                  );
                })}
              </AssetWrapper>
            </SubCategoryWrapper>
          );
        }}
      </DictionaryContext.Consumer>
    )
  );
};
AssetSubCategoryDetails.propTypes = {
  subCategory: PropTypes.array,
  capabilitiesSubCategoryLookupIds: PropTypes.array
};

export default AssetSubCategoryDetails;
