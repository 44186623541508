import styled from "styled-components";
import { Modal } from "react-bootstrap";

export const StyledModal = styled(Modal).attrs(() => ({ size: "xl" }))`
  padding-left: 0px !important;
  .modal-xl {
    margin: 0;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    overflow: auto;
    height: auto;
    max-width: 100%;
  }
  .modal-dialog {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: ${props => props.theme.backgroundColor.loaderBgColor};
    transition: all 0.1s ease-in-out;
  }
  &.fade.show .modal-dialog {
    bottom: 0;
    transition: all 0.1s ease-in-out;
  }
  .modal-content {
    border: 0;
    border-radius: 0;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    height: calc(100vh - 72px);
    margin-top: 52px;
    padding: 0px 10px 0 0;
    max-height: 700px;
    width: calc(100% - 16px);
    background-color: ${props => props.theme.backgroundColor.loaderBgColor};
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    @media ${props => props.theme.breakPoints.tabDevice} {
      padding: 20px 0 0 0;
      margin-top: 16px;
      max-height: 658px;
    }
    @media ${props => props.theme.breakPoints.mobileDevice} {
      padding: 20px 0 0 0;
      margin-top: 16px;
      max-height: 658px;
    }
    overflow: auto;
    &::-webkit-scrollbar {
      width: 8px;
      background-color: ${props => props.theme.backgroundColor.loaderBgColor};
    }
    &::-webkit-scrollbar-thumb {
      background: #8f90a6;
      border-radius: 10px;
    }
    /* Hide scrollbar for IE, Edge add Firefox  */
    -ms-overflow-style: none;
    /* Firefox */
    scrollbar-width: none;
  }
  .modal-body {
    padding: ${props => (props.filter ? "15px 121px" : 0)};
    margin-bottom: ${props => (props.filter ? "54px" : "63px")};
    max-width: ${props => !props.filter && "800px"};
    margin: ${props => !props.filter && "0 auto"};
    width: ${props => !props.filter && "100%"};
    overflow-y: unset;
    ::-webkit-scrollbar {
      display: none;
    }
    /* Hide scrollbar for IE, Edge add Firefox  */
    -ms-overflow-style: none;
    /* Firefox */
    scrollbar-width: none;
    height: ${props => (props.filter ? "calc(100% - 54px)" : "fit-content")};
    @media ${props => props.theme.breakPoints.mediumDesktopDevice} {
      max-width: 100%;
      padding: 0 20px;
      margin-bottom: 20px;
      height: fit-content;
    }
    @media ${props => props.theme.breakPoints.smallDesktopDevice} {
      max-width: 100%;
      padding: 0 20px;
      margin-bottom: 20px;
      height: fit-content;
    }
    @media ${props => props.theme.breakPoints.tabDevice} {
      max-width: 100%;
      padding: 0 20px;
      margin-bottom: 20px;
      height: fit-content;
    }
    @media ${props => props.theme.breakPoints.mobileDevice} {
      max-width: 100%;
      padding: 0 20px;
      margin-bottom: 20px;
      height: fit-content;
    }
  }
`;
export const PageModalWrapper = styled.div``;
