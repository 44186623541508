import React from "react";
import PropTypes from "prop-types";

import Strings from "../../resources/strings";
import ImageCard from "../CommonComponents/ImageCard/ImageCard";
import Images from "../../resources/images";
import { DictionaryContext } from "../../providers/DictionaryContext";
import {
  PaginationButton,
  PaginationItem,
  PaginationNumber,
  PaginationWrapper
} from "./style";

/**
 * Pagination is used to achieve pagination logic in all the pages. It's a common component
 * @param {Array} pageData - Data using which we need to display in UI
 * @param {Number} pageSize - Number of data to display
 * @param {func} onPageChange - Callback function to execute after pagination events
 * @param {Number} currentPage - Current page number
 * @param {Number} pageLimit - Number of pages to show in each series
 * @param {Number} currentPageSeries - Current page series number
 * @returns
 */
const Pagination = props => {
  let pageConfig = {
    totalPages: 0,
    pageSize: 6,
    pageLimit: 6,
    currentPageSeries: 1,
    pageNumbers: []
  };
  const {
    pageData,
    pageSize,
    onPageChange,
    currentPage,
    pageLimit,
    currentPageSeries
  } = props;

  pageConfig.pageSize = pageSize;
  pageConfig.totalPages = pageData && Math.ceil(pageData.length / pageSize);
  pageConfig.pageLimit = pageLimit;
  pageConfig.currentPageSeries = currentPageSeries;
  /**
   * Function to generate the array within particular range
   */
  function arrayRange(start, end) {
    return new Array(end - start).fill().map((d, i) => i + start);
  }

  /**
   * Function to handle on page click
   * @param {Number} value
   */
  const pageChangeHandler = value => {
    onPageChange(
      pageData.slice(
        value * pageConfig.pageSize,
        (value + 1) * pageConfig.pageSize
      ),
      value + 1,
      pageConfig.currentPageSeries
    );
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  /**
   * Function to handle next and prev functionality in pagination
   * @param {String} type
   */
  const pageButtonChangeHandler = type => {
    switch (type) {
      case Strings.prev:
        pageConfig.currentPageSeries -= 1;
        onPageChange(
          pageData.slice(
            pageConfig.currentPageSeries *
              pageConfig.pageLimit *
              pageConfig.pageSize -
              pageConfig.pageSize,
            pageConfig.currentPageSeries *
              pageConfig.pageLimit *
              pageConfig.pageSize
          ),
          pageConfig.currentPageSeries * pageConfig.pageLimit,
          pageConfig.currentPageSeries
        );
        window.scrollTo({ top: 0, behavior: "smooth" });
        break;
      case Strings.next:
        pageConfig.currentPageSeries += 1;
        onPageChange(
          pageData.slice(
            (pageConfig.currentPageSeries - 1) *
              pageConfig.pageLimit *
              pageConfig.pageSize,
            (pageConfig.currentPageSeries - 1) *
              pageConfig.pageLimit *
              pageConfig.pageSize +
              pageConfig.pageSize
          ),
          (pageConfig.currentPageSeries - 1) * pageConfig.pageLimit + 1,
          pageConfig.currentPageSeries
        );
        window.scrollTo({ top: 0, behavior: "smooth" });
        break;
      default:
        return;
    }
  };

  return pageConfig.totalPages > 1 ? (
    <DictionaryContext.Consumer>
      {({ dictionary }) => {
        return (
          <PaginationWrapper>
            {currentPageSeries !== 1 ? (
              <PaginationButton
                data-test="pagination-button"
                id="pag-num"
                onClick={() =>
                  pageButtonChangeHandler(dictionary?.prev || Strings.prev)
                }>
                <ImageCard
                  img={Images.GreyRightArrow}
                  width="16px"
                  height="16px"
                  mobWidth="16px"
                  mobHeight="16px"></ImageCard>
                {dictionary?.prev || Strings.prev}
              </PaginationButton>
            ) : null}
            {arrayRange(
              (pageConfig.currentPageSeries - 1) * pageConfig.pageLimit,
              pageConfig.currentPageSeries * pageConfig.pageLimit
            ).map((value, index) => {
              return (
                value < pageConfig.totalPages && (
                  <PaginationItem key={value}>
                    <PaginationNumber
                      isActive={currentPage === value + 1}
                      onClick={() => pageChangeHandler(value)}
                      data-test="pagination-number">
                      {value + 1}
                    </PaginationNumber>
                  </PaginationItem>
                )
              );
            })}
            {currentPageSeries !==
            Math.ceil(pageConfig.totalPages / pageConfig.pageLimit) ? (
              <PaginationButton
                data-test="pagination-button1"
                onClick={() =>
                  pageButtonChangeHandler(dictionary?.next || Strings.next)
                }>
                {dictionary?.next || Strings.next}
                <ImageCard
                  img={Images.GreyRightArrow}
                  width="16px"
                  height="16px"
                  mobWidth="16px"
                  mobHeight="16px"></ImageCard>
              </PaginationButton>
            ) : null}
          </PaginationWrapper>
        );
      }}
    </DictionaryContext.Consumer>
  ) : null;
};

Pagination.propTypes = {
  pageData: PropTypes.array,
  pageSize: PropTypes.number,
  onPageChange: PropTypes.func,
  currentPage: PropTypes.number,
  pageLimit: PropTypes.number,
  currentPageSeries: PropTypes.number
};

export default Pagination;
