import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";

import { StateContext } from "../../providers/StateContext";
import ProvidersFilterResults from "./ProvidersFilterResults";
import CapabilitiesFilterResults from "./CapabilitiesFilterResults";
import Strings from "../../resources/strings";
import NoResults from "./NoResults";
import { PageContainer } from "../../styles/commonStyles";
import { DictionaryContext } from "../../providers/DictionaryContext";

/**
 * CapabilitiesResult gives the information about the capabilities search results
 * @param {object} searchedData
 * @returns
 */
const CapabilitiesResult = props => {
  const { searchedData } = props;
  const state = useContext(StateContext);
  const {
    capabilitiesCategory,
    uniqueCapabilitiesCategory,
    providers,
    uniqueCapabilities,
    capabilities
  } = state;

  const [providersFilter, setProvidersFilter] = useState([]);
  const [capabilitiesFilter, setCapabilitiesFilter] = useState([]);
  const [isCategory, setIsCategory] = useState(false);

  useEffect(() => {
    let providersFilterResult = [];
    let capabilitiesFilterResult = [];

    const getCatCompKeys = data => {
      const companyIds = [];
      Object.keys(capabilitiesCategory).forEach(key => {
        const categoryData =
          capabilitiesCategory[key][data] &&
          capabilitiesCategory[key][data].length
            ? capabilitiesCategory[key][data]
            : [];
        categoryData.forEach(item => {
          !companyIds.includes(item.ProvidersLookupId) &&
            companyIds.push(item.ProvidersLookupId);
        });
      });
      return companyIds;
    };
    const getSubCatCompKeys = data => {
      const companyIds = [];
      const subCategoryData = [];
      Object.keys(uniqueCapabilitiesCategory).forEach(key => {
        uniqueCapabilitiesCategory[key].length &&
          uniqueCapabilitiesCategory[key].forEach(item => {
            item.Subcategory === data &&
              companyIds.push(item.ProvidersLookupId);
            item.Subcategory === data && subCategoryData.push(item);
          });
      });
      return [companyIds, subCategoryData];
    };

    const getFilterResults = ids => {
      let filterResult = [];
      ids.forEach(id => {
        providers.forEach(provider => {
          provider?.id === id && filterResult.push(provider);
        });
      });
      return filterResult;
    };
    switch (searchedData.filterType) {
      case Strings.assetCategoryProviders: {
        let uniqueCompanyIds = getCatCompKeys(searchedData.subTitle);
        providersFilterResult = getFilterResults(uniqueCompanyIds);
        break;
      }
      case Strings.assetSubCategoryProviders: {
        let uniqueIds = getSubCatCompKeys(searchedData.subTitle);
        providersFilterResult = getFilterResults(uniqueIds[0]);
        break;
      }
      case Strings.assetCategoryCapabilities:
        capabilitiesFilterResult = uniqueCapabilities.filter(
          data => data?.Category === searchedData.subTitle
        );
        setIsCategory(true);
        break;
      case Strings.assetSubCategoryCapabilities: {
        let subCategoryCapabilities = getSubCatCompKeys(searchedData.subTitle);
        capabilitiesFilterResult = subCategoryCapabilities[1];
        break;
      }
      default:
        capabilitiesFilterResult = capabilities.filter(
          data => data?.Title === searchedData.title
        );
        break;
    }

    setProvidersFilter(providersFilterResult);
    setCapabilitiesFilter(capabilitiesFilterResult);
  }, [
    searchedData,
    capabilitiesCategory,
    providers,
    uniqueCapabilities,
    capabilities,
    uniqueCapabilitiesCategory
  ]);

  return (
    <PageContainer>
      {providersFilter.length > 0 ? (
        <ProvidersFilterResults filterResult={providersFilter} />
      ) : capabilitiesFilter.length > 0 ? (
        <CapabilitiesFilterResults
          filterResult={capabilitiesFilter}
          isCategory={isCategory}
          searchedData={searchedData}
        />
      ) : (
        <DictionaryContext.Consumer>
          {({ dictionary }) => {
            return (
              <NoResults
                description={
                  dictionary?.noResultsDescription ||
                  Strings.noResultsDescription
                }
              />
            );
          }}
        </DictionaryContext.Consumer>
      )}
    </PageContainer>
  );
};

CapabilitiesResult.propTypes = {
  searchedData: PropTypes.instanceOf(Object)
};

export default CapabilitiesResult;
