import Strings from "../resources/strings";

// tabTitle provides titles for every tab of ProviderDetails Page
export const tabTitle = [
  Strings.overview,
  Strings.valueProposition,
  Strings.assets,
  Strings.partnershipOpportunities,
  Strings.caseStudies,
  Strings.contact
];

// breadCrumbData provides link and linkName for BreadCrumb which will use in ProviderDetailsPage
export const breadCrumbData = [
  {
    linkName: "providers",
    link: "/auth/providers"
  },
  {
    linkName: "information",
    link: "/auth"
  }
];

// IconLogoData provides the image Name for Capabilities logo
const capabilitiesLogo = {
  "technology": "TechnologyIcon",
  "talent": "TalentIcon",
  "intellectual property": "IntellectualIcon",
  "networks": "NetworksIcon",
  "brand": "BrandIcon",
  "sales pipeline": "SalesIcon",
  "data": "DataIcon"
};
export const getCapabilitiesLogo = title => {
  return capabilitiesLogo[title?.toLowerCase()] || "TechnologyIcon";
};
