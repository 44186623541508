import React from "react";
import PropTypes from "prop-types";

import { LinkWrapper } from "./style";

/**
 * CustomLink component is common which is useful to define linked component
 * @param {string} to - defines path for navigation
 * @param {func} onClick - on click of link handler function
 * @param {node} children - which helps to navigate given path
 * @example
 * CustomLink({to, children})
 */
const CustomLink = props => {
  const { to, onClick, children } = props;
  return (
    <LinkWrapper to={to} onClick={onClick}>
      {children}
    </LinkWrapper>
  );
};

CustomLink.propTypes = {
  to: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node
};

CustomLink.defaultProps = {
  to: "/"
};

export default CustomLink;
