import styled from "styled-components";

export const BreadCrumbs = styled.div`
  display: flex;
  & > :last-child {
    pointer-events: none;
  }
  & > :last-child > * > :first-child {
    color: ${props => props.theme.fontColor.inactiveBlack};
  }
  & > :last-child > * > :last-child {
    display: none;
  }
`;

export const BreadCrumbWrapper = styled.div`
  display: flex;
  & > :last-child {
    margin: 0px 4px;
  }
`;

export const LinkName = styled.p`
  color: ${props => props.theme.fontColor.linkColor};
  font-weight: ${props => props.theme.fontWeight.bold};
  font-size: ${props => props.theme.fontSize.tertiary};
  line-height: ${props => props.theme.lineHeight.normal};
  margin-bottom: 0;
`;
