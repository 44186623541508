import React from "react";
import PropTypes from "prop-types";

import Images from "../../resources/images";
import ImageCard from "../CommonComponents/ImageCard/ImageCard";
import CustomLink from "./../CommonComponents/Link/Link";
import { convertToUpperCase } from "../../utils/commonUtils";
import { BreadCrumbs, BreadCrumbWrapper, LinkName } from "./style";

/**
 * BreadCrumb component will be use to define current path and to navigate define links
 * @param {Array} links - defines links name and route
 * @param {Function} closeModal - function to close the modal
 * @example
 * BreadCrumb({links})
 */
const BreadCrumb = props => {
  const { links, closeModal } = props;
  return (
    <BreadCrumbs>
      {links.map(item => (
        <CustomLink to={item.link} key={item.linkName} onClick={closeModal}>
          <BreadCrumbWrapper>
            <LinkName>{convertToUpperCase(item.linkName)}</LinkName>
            <ImageCard
              img={Images.RightArrow}
              width="16px"
              height="16px"
              mobWidth="16px"
              mobHeight="16px"
              alt="arrow"
            />
          </BreadCrumbWrapper>
        </CustomLink>
      ))}
    </BreadCrumbs>
  );
};

BreadCrumb.propTypes = {
  links: PropTypes.instanceOf(Array),
  closeModal: PropTypes.func
};

export default BreadCrumb;
