import React, { Fragment } from "react";
import PropTypes from "prop-types";

import CardGrid from "../CommonComponents/CardGrid/CardGrid";
import ProviderDetailsSummary from "../ProviderDetailsSummary/ProviderDetailsSummary";
import Strings from "../../resources/strings";
import { DictionaryContext } from "../../providers/DictionaryContext";
import { SummaryFilterText } from "./style";

/**
 * SummaryFilterResults is used to render the search results of summary
 * @param {object} filterResult
 * @param {array} summaryFilterArray
 * @param {object} searchedData
 * @example
 * SummaryFilterResults({filterResult, summaryFilterArray})
 */
const SummaryFilterResults = props => {
  const { filterResult, summaryFilterArray, searchedData } = props;
  return (
    <Fragment>
      <DictionaryContext.Consumer>
        {({ dictionary }) => {
          return (
            <SummaryFilterText>
              {summaryFilterArray.length +
                " " +
                (dictionary?.singleResult || Strings.singleResult)}
            </SummaryFilterText>
          );
        }}
      </DictionaryContext.Consumer>

      <CardGrid noTitle={true}>
        {filterResult && (
          <ProviderDetailsSummary
            provider={{
              ...filterResult,
              CategoryArray: searchedData?.filtertype?.contains(
                Strings.category
              )
                ? filterResult?.CategoryArray?.filter(
                    category =>
                      category.toLowerCase() ===
                      searchedData?.subTitle0?.toLowerCase()
                  )
                : filterResult?.CategoryArray
            }}
            isSearch={true}
            filteredHighlights={filterResult?.Highlights}
          />
        )}
      </CardGrid>
    </Fragment>
  );
};

SummaryFilterResults.propTypes = {
  filterResult: PropTypes.instanceOf(Object),
  summaryFilterArray: PropTypes.arrayOf(Object),
  searchedData: PropTypes.object
};

export default SummaryFilterResults;
