import React from "react";
import PropTypes from "prop-types";

import ReadMoreCTA from "../../CommonComponents/ReadMoreCTA/ReadMoreCTA";
import ImageCard from "../../CommonComponents/ImageCard/ImageCard";
import Strings from "../../../resources/strings";
import { DictionaryContext } from "../../../providers/DictionaryContext";
import {
  CardWrapper,
  CardHeadingWrapper,
  CardImageWrapper,
  CardTitle,
  CardContentWrapper,
  CardDescription,
  CardDetails,
  CardDetailWrapper,
  CardSubTitle,
  CardSubDescription,
  CardLinkWrapper,
  CardContent
} from "../../../styles/commonStyles";
import { getRefactoredImageUrl } from "../../../utils/commonUtils";

/**
 * CustomCard component which can be use to show details of providers
 * @param {Object} Company_x0020_Logo
 * @param {*} headingBgColor
 * @param {String} Title
 * @param {String} Abstract
 * @param {String} PartnerShip_x0020_Potential
 * @param {Object} Category
 */

const ProviderCard = ({ provider, headingBgColor, redirectTo }) => {
  const {
    Company_x0020_Logo,
    Title,
    Abstract,
    PartnerShip_x0020_Potential,
    CategoryArray
  } = provider;

  return (
    <DictionaryContext.Consumer>
      {({ dictionary }) => {
        return (
          <CardWrapper data-test="card-wrapper">
            <CardHeadingWrapper headingBgColor={headingBgColor}>
              <CardImageWrapper>
                <ImageCard
                  img={getRefactoredImageUrl(Company_x0020_Logo?.Url)}
                  alt="card-image"
                  width="44px"
                  isObjectContain={true}
                  height="44px"
                  mobHeight="34px"
                  mobWidth="34px"
                />
              </CardImageWrapper>
              <CardTitle isFixWidth={"calc(100% - 76px)"}>{Title}</CardTitle>
            </CardHeadingWrapper>
            <CardContentWrapper>
              <CardContent>
                <CardDescription>{Abstract}</CardDescription>
                <CardDetails>
                  <CardDetailWrapper>
                    <CardSubTitle>
                      {dictionary?.partnershipPotential ||
                        Strings.partnershipPotential}
                    </CardSubTitle>
                    <CardSubDescription>
                      {PartnerShip_x0020_Potential}
                    </CardSubDescription>
                  </CardDetailWrapper>
                  <CardDetailWrapper>
                    <CardSubTitle>
                      {dictionary?.categoryTitle || Strings.categoryTitle}
                    </CardSubTitle>
                    <CardSubDescription>
                      {CategoryArray?.length > 1
                        ? CategoryArray?.join(", ")?.trim()?.slice(0, -1)
                        : CategoryArray}
                    </CardSubDescription>
                  </CardDetailWrapper>
                </CardDetails>
              </CardContent>
              <CardLinkWrapper>
                <ReadMoreCTA
                  redirectTo={redirectTo}
                  textcolor="blue"
                  linkText={dictionary?.readMore || Strings.readMore}
                />
              </CardLinkWrapper>
            </CardContentWrapper>
          </CardWrapper>
        );
      }}
    </DictionaryContext.Consumer>
  );
};

ProviderCard.propTypes = {
  Company_x0020_Logo: PropTypes.object,
  headingBgColor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.bool
  ]),
  Title: PropTypes.string,
  Abstract: PropTypes.string,
  PartnerShip_x0020_Potential: PropTypes.string,
  Category: PropTypes.object,
  redirectTo: PropTypes.string
};

export default ProviderCard;
