import styled from "styled-components";

export const MobileNavbarWrapper = styled.div`
  display: none;
  @media ${props => props.theme.breakPoints.tabDevice} {
    position: sticky;
    bottom: 0;
    display: flex;
    width: 100%;
    padding: 18px 34px 6px 32px;
    background-color: white;
    box-sizing: border-box;
  }
  @media ${props => props.theme.breakPoints.mobileDevice} {
    position: sticky;
    bottom: 0;
    display: flex;
    width: 100%;
    padding: 18px 34px 6px 32px;
    background-color: white;
    box-sizing: border-box;
  }
`;
