import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";

import * as JsSearch from "js-search";

import ImageCard from "../CommonComponents/ImageCard/ImageCard";
import LineSeparator from "../CommonComponents/LineSeparator/LineSeparator";
import OptionsList from "./OptionsList";
import { StateContext } from "../../providers/StateContext";
import Images from "../../resources/images";
import Strings from "../../resources/strings";

import {
  SearchInputWrapper,
  SearchInput,
  SearchIconWrapper,
  SearchParentWrapper,
  ClearSearchWrapper
} from "./style";

/**
 * Search component is used to render UI for search component
 * @param {string} searchIcon
 * @param {string} searchIconText
 * @param {string} searchIconHeight
 * @param {string} searchIconWidth
 * @param {string} searchPlaceholder
 * @param {string} searchIconMobWidth
 * @param {string} searchIconMobHeight
 * @example
 * Search({
    searchIcon,
    searchIconText,
    searchIconHeight,
    searchIconWidth,
    searchPlaceholder,
    searchIconMobWidth,
    searchIconMobHeight})
 */
const Search = props => {
  const state = useContext(StateContext);
  const { providers, capabilities } = state;

  const [currentOption, setCurrentOption] = useState(-1);
  const [searchProviders, setSearchProviders] = useState(null);
  const [searchCapabilities, setSearchCapabilities] = useState(null);
  const [filteredOptions, setFilteredOptions] = useState({
    filteredProviderOptions: [],
    filteredCapabilitiesOptions: []
  });
  const [showOptions, setShowOptions] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [dataProviders, setDataProviders] = useState([]);
  const [dataCapabilities, setDataCapabilities] = useState([]);
  const [suggestionLimit, setSuggestionLimit] = useState(0);
  const [enterKeyPressed, setEnterKeyPressed] = useState(false);
  const [downKeyPressed, setDownKeyPressed] = useState(false);

  const {
    searchIcon,
    searchIconText,
    searchIconHeight,
    searchIconWidth,
    searchPlaceholder,
    searchIconMobWidth,
    searchIconMobHeight,
    clearSearchIcon,
    clearSearchAltText,
    clearSearchIconHeight,
    clearSearchIconWidth,
    clearSearchMobHeight,
    clearSearchMobWidth,
    getData
  } = props;

  useEffect(() => {
    if (providers && capabilities) {
      const searchProvidersData = new JsSearch.Search("id");
      const searchCapabilitiesData = new JsSearch.Search("id");

      searchProvidersData.addIndex("Title");
      searchProvidersData.addIndex("ComputedField_Industry");
      searchProvidersData.addIndex("CategoryArray");
      searchProvidersData.addDocuments(providers);

      searchCapabilitiesData.addIndex("Category");
      searchCapabilitiesData.addIndex("Subcategory");
      searchCapabilitiesData.addIndex("Title");
      searchCapabilitiesData.addDocuments(capabilities);

      setSearchProviders(searchProvidersData);
      setSearchCapabilities(searchCapabilitiesData);
    }
  }, [providers, capabilities]);

  const searchInputHandler = e => {
    const searchInput = e.currentTarget.value;
    const filteredProviderData = searchProviders.search(searchInput);
    const filteredCapabilitiesData = searchCapabilities.search(searchInput);
    setCurrentOption(-1);
    setShowOptions(true);
    setSearchInput(e.currentTarget.value);
    searchInput.length > 1 &&
      setFilteredOptions({
        filteredProviderOptions: [...filteredProviderData],
        filteredCapabilitiesOptions: [...filteredCapabilitiesData]
      });
    if (searchInput?.length < 1) {
      setSearchInput("");
      getData(null);
    }
  };

  const clearSearchBox = () => {
    setFilteredOptions({
      filteredProviderOptions: [],
      filteredCapabilitiesOptions: []
    });
    setShowOptions(false);
    setSearchInput("");
    getData(null);
    setEnterKeyPressed(false);
    setDownKeyPressed(false);
  };

  const getListsDataProviders = data => {
    setDataProviders(data);
  };

  const getListsDataCapabilities = data => {
    setDataCapabilities(data);
  };

  const onKeyUp = e => {
    if (e.keyCode === 8) {
      if (searchInput?.length === 0 || searchInput === "") {
        setFilteredOptions({
          filteredProviderOptions: [],
          filteredCapabilitiesOptions: []
        });
        getData(null);
        setEnterKeyPressed(false);
        setDownKeyPressed(false);
      }
    }
  };

  const onKeyDown = e => {
    if (e.keyCode === 8) {
      if (searchInput?.length === 0 || searchInput === "") {
        setFilteredOptions({
          filteredProviderOptions: [],
          filteredCapabilitiesOptions: []
        });
        getData(null);
        setEnterKeyPressed(false);
        setDownKeyPressed(false);
      }
    } else if (e.keyCode === 13) {
      let providersArray = [];
      let capabilitiesArray = [];
      dataProviders.forEach(eachData => {
        eachData.filterType === Strings.companyCategory ||
        eachData.filterType === Strings.companyIndustry
          ? providersArray.push(eachData?.title + " " + Strings.providers)
          : providersArray.push(eachData?.title);
      });
      dataCapabilities.forEach(eachData => {
        capabilitiesArray.push(eachData?.title);
      });

      let totalRenderData = [...dataProviders, ...dataCapabilities];
      let totalInputData = [...providersArray, ...capabilitiesArray];
      if (downKeyPressed) {
        setSearchInput(totalInputData[currentOption]);
        getData(totalRenderData[currentOption]);
        setCurrentOption(-1);
        setShowOptions(false);
        setFilteredOptions({
          filteredProviderOptions: [],
          filteredCapabilitiesOptions: []
        });
      }
      if (
        filteredOptions.filteredProviderOptions.length === 0 &&
        filteredOptions.filteredCapabilitiesOptions.length === 0
      ) {
        searchInput?.length > 0 && getData(Strings.noMatch);
        setEnterKeyPressed(true);
      }
    } else if (e.keyCode === 38) {
      currentOption !== 0 && setCurrentOption(currentOption - 1);
    } else if (e.keyCode === 40) {
      currentOption !== suggestionLimit - 1 &&
        setCurrentOption(currentOption + 1);

      setDownKeyPressed(true);
    }
  };

  const getSuggestionLimit = data => {
    setSuggestionLimit(data);
  };

  return (
    <SearchParentWrapper>
      <SearchInputWrapper>
        <SearchIconWrapper>
          <ImageCard
            img={searchIcon}
            alt={searchIconText}
            height={searchIconHeight}
            width={searchIconWidth}
            mobWidth={searchIconMobWidth}
            mobHeight={searchIconMobHeight}
          />
        </SearchIconWrapper>
        <SearchInput
          placeholder={searchPlaceholder}
          onChange={e => searchInputHandler(e)}
          value={searchInput}
          searchInput={searchInput}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          currentOption={currentOption}
          filteredOptions={filteredOptions}
          data-test="search"
        />
        {(searchInput?.length > 0 || enterKeyPressed) && (
          <ClearSearchWrapper
            onClick={() => clearSearchBox()}
            data-test="clear-search-wrapper">
            <ImageCard
              img={clearSearchIcon}
              alt={clearSearchAltText}
              height={clearSearchIconHeight}
              width={clearSearchIconWidth}
              mobWidth={clearSearchMobWidth}
              mobHeight={clearSearchMobHeight}
            />
          </ClearSearchWrapper>
        )}
        {(showOptions &&
          searchInput &&
          filteredOptions.filteredProviderOptions.length) ||
        filteredOptions.filteredCapabilitiesOptions.length ? (
          <OptionsList
            filteredOptions={filteredOptions}
            currentOption={currentOption}
            searchInput={searchInput}
            setFilteredOptions={setFilteredOptions}
            setSearchInput={setSearchInput}
            getData={getData}
            getListsDataProviders={getListsDataProviders}
            getListsDataCapabilities={getListsDataCapabilities}
            getSuggestionLimit={getSuggestionLimit}
            data-list="options-list"
          />
        ) : (
          ""
        )}
      </SearchInputWrapper>
      <LineSeparator />
    </SearchParentWrapper>
  );
};

Search.propTypes = {
  searchIcon: PropTypes.string,
  searchText: PropTypes.string,
  searchIconHeight: PropTypes.string,
  searchIconWidth: PropTypes.string,
  searchIconMobWidth: PropTypes.string,
  searchIconMobHeight: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  getData: PropTypes.func
};

Search.defaultProps = {
  searchIcon: Images.SearchSvg,
  clearSearchIcon: Images.ClearTextSearch,
  clearSearchAltText: "clear search icon",
  clearSearchIconHeight: "24px",
  clearSearchIconWidth: "24px",
  clearSearchMobHeight: "24px",
  clearSearchMobWidth: "24px",
  searchText: "search icon",
  searchIconHeight: "24px",
  searchIconWidth: "24px",
  searchIconMobWidth: "24px",
  searchIconMobHeight: "24px",
  searchPlaceholder: "Search Capabilities and Providers"
};

export default Search;
