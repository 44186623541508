import {
  PublicClientApplication,
  InteractionRequiredAuthError
} from "@azure/msal-browser";
import { encryptData } from "../../services/security";
import { msalConfig, isBrowser } from "./authConfig";

const GRAPH_SCOPES = {
  OPENID: "openid",
  PROFILE: "profile",
  USER_READ: "user.read",
  MAIL_READ: "mail.read"
};

const GRAPH_ENDPOINTS = {
  ME: "https://graph.microsoft.com/v1.0/me",
  PROFILE_IMAGE: "https://graph.microsoft.com/v1.0/me/photo/$value"
};

const GRAPH_REQUESTS = {
  LOGIN: {
    scopes: [GRAPH_SCOPES.OPENID, GRAPH_SCOPES.PROFILE, GRAPH_SCOPES.USER_READ]
  }
};

let state = {
  account: null,
  error: null,
  user: null,
  accessToken: null
};

export const fetchMsGraph = async (url, accessToken) => {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers
  };

  const response = await fetch(url, options);

  return response;
};

const msalApp = isBrowser && new PublicClientApplication(msalConfig);

/**
 * handleRedirectPromise() allows user to fire events after the PublicClientApplication object has loaded during redirect flows
 * @exmple handleRedirectPromise()
 */
isBrowser &&
  msalApp
    .handleRedirectPromise()
    .then(handleResponse)
    .catch(error => {
      const errorMessage = error.errorMessage
        ? error.errorMessage
        : "Unable to acquire access token.";
      state.error = errorMessage;
    });

/**
 * handleResponse(response) returns the response object on login
 * @param {*} response
 * @example handleResponse(response)
 */
function handleResponse(response) {
  if (response && response !== null) {
    msalApp.setActiveAccount(response.account);
    if (response.tokenType === "Bearer") {
      window.sessionStorage.setItem("LoginTime", Date.now());
      state.accessToken = response.accessToken;
      state.account = response.account;
    }
  }
}

/**
 * acquireToken(request) is used to get access token based on user iteraction for API calls
 * @param {*} request
 * @example acquireToken(request)
 */
const acquireToken = request => {
  if (!isBrowser) {
    return null;
  }
  return msalApp.acquireTokenSilent(request).catch(error => {
    // Call acquireTokenRedirect in case of acquireTokenSilent failure
    // due to consent or interaction required ONLY
    if (error instanceof InteractionRequiredAuthError) {
      return msalApp.acquireTokenRedirect({
        ...request,
        redirectUri: process.env.LOGIN_REDIRECT_URL
      });
    }
  });
};

/**
 * fetchProfile() is used to fetch profile of loggedin user
 * @example fetchProfile()
 */
export const fetchProfile = async () => {
  const tokenResponse = await acquireToken(GRAPH_REQUESTS.LOGIN);

  if (tokenResponse) {
    const graphProfile = await fetchMsGraph(
      GRAPH_ENDPOINTS.ME,
      tokenResponse.accessToken
    )
      .then(res => res.json())
      .catch(() => {
        state.error = "Unable to fetch Graph profile.";
      });

    if (graphProfile && Object.keys(graphProfile).length) {
      state.user = graphProfile;
      let encryptedUserId = encryptData({ data: graphProfile?.id });
      window.sessionStorage.setItem("userId", encryptedUserId);
      window.sessionStorage.setItem("userName", graphProfile?.displayName);
    }
    return graphProfile;
  }
};

/**
 *
 * @param {*} response
 * @returns
 */
export const validateResponse = response => {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
};

/**
 * fetchProfileImage(email) is used to fetch the graph image of the logged in user
 * @param {*} email
 * @exmple fetchProfileImage(email)
 */
export const fetchProfileImage = async (email = "") => {
  const accessTokenResponse = await getAuthToken(GRAPH_REQUESTS.LOGIN);
  let url = GRAPH_ENDPOINTS.PROFILE_IMAGE;
  if (email) {
    url = `https://graph.microsoft.com/v1.0/users/${email}/photo/$value`;
  }
  if (accessTokenResponse) {
    const profileImage = fetchMsGraph(url, accessTokenResponse).catch(() => {
      state.error = "Unable to fetch Graph profile.";
    });
    return profileImage
      .then(validateResponse)
      .then(response => response.blob())
      .then(blob => URL.createObjectURL(blob))
      .catch(err => {});
  }
};

/**
 * onSignIn() is used to login to the azure portal
 * @param {}
 * @example onSignIn()
 */
export const onSignIn = () => {
  isBrowser &&
    msalApp.loginRedirect({
      ...GRAPH_REQUESTS.LOGIN,
      redirectUri: process.env.LOGIN_REDIRECT_URL
    });
};

/**
 * checkSession(callback) is used to identify if the user is already logged in or if a new user logged in
 * @param {*} callback
 * @example checkSession(callback)
 */
export const checkSession = async callback => {
  if (!isBrowser) {
    return false;
  }
  try {
    const account = msalApp.getActiveAccount();
    if (account) {
      state.account = account;
      let user = {};
      user.name = account.name;
      user.email = account.userName;
      state.user = user;
      callback();
      fetchProfile();
    } else if (!state.account) {
      onSignIn();
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.debug(error);
  }
};

/**
 * isAuthenticated() is used to check if a user is logged in or not
 * @param {}
 * @example isAuthenticated()
 */
export const isAuthenticated = () => {
  return !!state.account;
};

/**
 * logout() is used to logging out the user
 * @param {}
 * @example logout()
 */
export const logout = () => {
  const logoutRequest = {
    account: msalApp.getAccountByHomeId(state.account?.homeAccountId)
  };
  state = {};

  msalApp.logoutRedirect({
    ...logoutRequest
  });
};

/**
 * getAuthToken() returns the access token to make API calls
 * @param {}
 * @example getAuthToken()
 */
export const getAuthToken = async () => {
  const accounts = msalApp.getAllAccounts();
  var accessTokenResponse;
  if (accounts.length > 0) {
    var request = {
      ...GRAPH_REQUESTS.LOGIN,
      account: accounts[0]
    };
    accessTokenResponse = await acquireToken(request);
    window.sessionStorage.setItem("loggingIn", true);
    return accessTokenResponse?.accessToken;
  } else {
    return null;
  }
};
