import React, { useState, useEffect, useRef } from "react";

import Strings from "../../../resources/strings";
import {
  fetchProfile,
  fetchProfileImage,
  logout
} from "../../../utils/auth/auth";
import LineSeparator from "../LineSeparator/LineSeparator";
import { DictionaryContext } from "../../../providers/DictionaryContext";
import {
  CustomModal,
  ProfileContainer,
  Profile,
  PopupContainer,
  UserDetailsContainer,
  UserName,
  EmailId,
  SignOutButton,
  InfoContainer
} from "./style";
import { postApiCall } from "../../../services/api";
import { getRefactoredImageUrl, getUserId } from "../../../utils/commonUtils";

const ProfileAvatar = () => {
  const [showProfile, setShowProfile] = useState(false);
  const [imageBlob, setImageBlob] = useState();
  const [user, setUser] = useState({ userName: "", email: "" });
  const componentRef = useRef(null);

  useEffect(() => {
    fetchProfile().then(profile => {
      setUser({
        userName: profile && profile.displayName,
        email: profile && profile.userPrincipalName
      });
    });
  }, []);

  useEffect(() => {
    fetchProfileImage().then(blob => {
      setImageBlob(blob);
    });
  }, []);

  const closeProfilePopup = () => {
    componentRef.current.classList.remove("fade");
    setTimeout(() => {
      setShowProfile(false);
    }, 300);
  };

  const signOut = () => {
    postApiCall
      .postUserInformation({
        userId: getUserId(),
        logoutTime: parseInt(Date.now())
      })
      .then(res => {
        window.sessionStorage.removeItem("loggingIn");
        logout();
      });
  };
  return (
    <DictionaryContext.Consumer>
      {({ dictionary }) => {
        return (
          <ProfileContainer ref={componentRef}>
            <Profile
              role="button"
              aria-hidden="true"
              tabIndex="0"
              onKeyPress={evt =>
                evt.key === "Enter" ? setShowProfile(true) : null
              }
              onClick={() => setShowProfile(true)}>
              <img
                src={
                  imageBlob
                    ? imageBlob
                    : getRefactoredImageUrl(dictionary?.ProfilePlaceholder)
                }
                alt="Profile"
              />
            </Profile>
            <CustomModal
              data-test="show-profile"
              show={showProfile}
              onHide={closeProfilePopup}
              id="profilePopup">
              <CustomModal.Body>
                <PopupContainer data-test="profileclick">
                  <UserDetailsContainer>
                    <InfoContainer>
                      <UserName>
                        {user && user.userName ? user.userName : "User"}
                      </UserName>
                      <EmailId>{user && user.email ? user.email : ""}</EmailId>
                    </InfoContainer>
                    <Profile>
                      <img
                        src={
                          imageBlob
                            ? imageBlob
                            : getRefactoredImageUrl(
                                dictionary?.ProfilePlaceholder
                              )
                        }
                        alt="Profile"
                      />
                    </Profile>
                  </UserDetailsContainer>
                  <LineSeparator />
                  <UserDetailsContainer>
                    <SignOutButton onClick={() => signOut()}>
                      {dictionary?.signOut || Strings.signOut}
                    </SignOutButton>
                  </UserDetailsContainer>
                </PopupContainer>
              </CustomModal.Body>
            </CustomModal>
          </ProfileContainer>
        );
      }}
    </DictionaryContext.Consumer>
  );
};

export default ProfileAvatar;
