import styled, { css } from "styled-components";

import { AssetDescription, AssetTitle } from "../../styles/commonStyles";

export const AccordionContainer = styled.li`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const AccordionWrapper = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: none;
  border: none;
  width: 100%;
  text-align: left;
`;
export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 13px 0;
  scroll-margin: 52px;
  & > img {
    ${props =>
      props.isActive &&
      css`
        transform: rotate(180deg);
      `}
    margin-right: 20px;
  }

  @media ${props => props.theme.breakPoints.tabDevice} {
    padding: ${props => props.filter && "8px 0 17px"};
  }
  @media ${props => props.theme.breakPoints.mobileDevice} {
    padding: ${props => props.filter && "8px 0"};
  }
`;
export const Dropdown = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Description = styled(AssetDescription)`
  margin-bottom: 18px;
  margin-top: 16px;
`;
export const AccordionTitle = styled(AssetTitle)`
  margin: 0;
  line-height: ${props =>
    props.filter
      ? props.theme.lineHeight.content
      : props.theme.lineHeight.default};
  color: ${props =>
    props.isSelected
      ? props.theme.fontColor.activeBlack
      : props.theme.fontColor.inactiveBlack};
  @media ${props => props.theme.breakPoints.tabDevice} {
    margin: 0 27px 0 0;
  }
  @media ${props => props.theme.breakPoints.mobileDevice} {
    margin: 0 27px 0 0;
    font-size: ${props => props.theme.fontSize.secondary};
  }
`;
