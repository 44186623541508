import React from "react";
import PropTypes from "prop-types";

import { TertiaryButton } from "../../styles/commonStyles";
import { ButtonWrapper } from "./style";

/**
 * ProviderButtons component is used to render message and share profile buttons in provider details summary component
 * @param {string} shareProfileBtnText
 * @param {string} messageBtnText
 * @example
 * ProviderButtons({shareProfileBtnText, messageBtnText})
 */
const ProviderButtons = props => {
  const { messageBtnText, handleScroll } = props;

  return (
    <ButtonWrapper>
      <TertiaryButton type="button" onClick={handleScroll}>
        {messageBtnText}
      </TertiaryButton>
    </ButtonWrapper>
  );
};

ProviderButtons.propTypes = {
  messageBtnText: PropTypes.string
};

ProviderButtons.defaultProps = {
  messageBtnText: "Message"
};

export default ProviderButtons;
