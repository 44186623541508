import React, { useState } from "react";

import Menu from "./../Sidebar/Menu";
import { MobileNavbarWrapper } from "./style";

/**
 * MobileNavbar component is a footer navigation bar for a mobile view
 * @example
 * MobileNavbar()
 */
const MobileNavbar = () => {
  const [clicked, setClicked] = useState(false);

  return (
    <MobileNavbarWrapper>
      <Menu
        data-test="clicked"
        clicked={() => setClicked(true)}
        click={clicked}
        mobileView={true}
      />
    </MobileNavbarWrapper>
  );
};

export default MobileNavbar;
