import styled from "styled-components";

import { Button } from "../../styles/commonStyles";

export const PaginationWrapper = styled.ul`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 74px 0 30px;
  @media ${props => props.theme.breakPoints.mobileDevice} {
    margin: 54px 0 30px;
    flex-wrap: wrap;
    padding: 0px;
  }
`;

export const PaginationItem = styled.li`
  list-style-type: none;
`;

export const PaginationNumber = styled.button`
  font-size: ${props => props.theme.fontSize.primary};
  font-weight: ${props => props.theme.fontWeight.bold};
  color: ${props =>
    props.isActive
      ? props.theme.fontColor.white
      : props.theme.fontColor.darkGrey};
  border: none;
  padding: 8px 20px 9px;
  border-radius: 6px;
  background: ${props =>
    props.isActive ? props.theme.backgroundColor.blue : "transparent"};
  @media ${props => props.theme.breakPoints.mobileDevice} {
    padding: 0;
    width: 32px;
    height: 30px;
    font-size: ${props => props.theme.fontSize.tertiary};
  }
  &:hover {
    text-decoration: ${props => (props.isActive ? "none" : "underline")};
  }
`;

export const PaginationButton = styled(Button)`
  min-width: 93px;
  margin: 0 0 0 19px;
  padding: 8px 0 9px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background-color: ${props => props.theme.backgroundColor.greyBtn};
  font-size: ${props => props.theme.fontSize.primary};
  font-weight: ${props => props.theme.fontWeight.bold};
  color: ${props => props.theme.fontColor.activeBlack};
  border: none;
  &:first-child {
    margin: 0 19px 0 0;
    padding: 8px 8px 9px 0;
    & > img {
      transform: rotate(180deg);
    }
  }
  &:hover {
    color: ${props => props.theme.fontColor.activeBlack};
    border: none;
    text-decoration: underline;
    background-color: ${props => props.theme.backgroundColor.greyBtn};
  }
  @media ${props => props.theme.breakPoints.mobileDevice} {
    font-size: ${props => props.theme.fontSize.tertiary};
    min-width: 64px;
    padding: 3px 0 3px 8px;
    &:first-child {
      margin: 0 19px 0 0;
      padding: 3px 3px 3px 3px;
    }
  }
  @media ${props => props.theme.breakPoints.smallMobileDevice} {
    margin: 0 0 0 3px;
    &:first-child {
      margin: 0 3px 0 0;
    }
  }
`;
