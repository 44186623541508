import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";

import Strings from "../../resources/strings";
import { OptionsListItem } from "./style";
import { postApiCall } from "../../services/api";
import { getUserId } from "../../utils/commonUtils";

/**
 * CapabilitiesListItem is used to render the providers list items
 * @param {array} capabilitiesList
 * @param {string} searchInput
 * @param {number} currentOption
 * @param {bool} isKeyPressed
 * @param {func} setFilteredOptions
 * @param {func} getData
 * @param {func} maxSuggestionLimit
 * 
 *@example CapabilitiesListItem({
   capabilitiesList,
    searchInput,
    currentOption,
    setFilteredOptions,
    setSearchInput,
    getData,
    maxSuggestionLimit
  })
 */
const CapabilitiesListItem = props => {
  const {
    capabilitiesList,
    searchInput,
    currentOption,
    setFilteredOptions,
    setSearchInput,
    getData,
    maxSuggestionLimit,
    getListsDataCapabilities,
    getSuggestionLimit
  } = props;
  const [listItems, setListItems] = useState({
    uniqueAssetName: [],
    uniqueCategoryProviders: [],
    uniqueCategoryCapabilities: [],
    uniqueSubCategoryProviders: [],
    uniqueSubCategoryCapabilities: []
  });

  // to keep track of the row index in the capabilities list
  let rowIndex = maxSuggestionLimit - 1;

  const getRowData = item => {
    setFilteredOptions({
      filteredProviderOptions: [],
      filteredCapabilitiesOptions: []
    });
    postApiCall.postSearchInput({
      userId: getUserId(),
      searchKeyWord: item.title,
      page: window.location.href.split("/").slice(-1)[0],
      searchedTime: Date.now()
    });
    setSearchInput(item.title);
    getData(item);
  };

  useEffect(() => {
    const assetName = [];
    const categoryProviders = [];
    const categoryCapabilities = [];
    const subCategoryProviders = [];
    const subCategoryCapabilities = [];
    let allDataArray = [];

    let capMaxSuggestionLimit = 0;

    capabilitiesList.forEach(listItem => {
      if (capMaxSuggestionLimit + maxSuggestionLimit >= 10) return;
      if (listItem.Title.toLowerCase().includes(searchInput?.toLowerCase())) {
        if (
          !assetName.find(
            eachAssetName => eachAssetName.title === listItem.Title
          )
        ) {
          assetName.push({
            key: Strings.capabilities,
            title: listItem.Title,
            filterType: Strings.assetName
          });
          capMaxSuggestionLimit = capMaxSuggestionLimit + 1;
        }
      }
      if (
        listItem.Category.toLowerCase().includes(searchInput?.toLowerCase())
      ) {
        if (
          !categoryProviders.find(
            eachCatProvider => eachCatProvider.subTitle === listItem.Category
          )
        ) {
          categoryProviders.push({
            key: Strings.capabilities,
            title: listItem.Category + " " + Strings.providers,
            subTitle: listItem.Category,
            filterType: Strings.assetCategoryProviders
          });
          capMaxSuggestionLimit = capMaxSuggestionLimit + 1;
        }

        if (
          !categoryCapabilities.find(
            eachCatCapabilities =>
              eachCatCapabilities.subTitle === listItem.Category
          )
        ) {
          categoryCapabilities.push({
            key: Strings.capabilities,
            title: listItem.Category + " " + Strings.assets,
            subTitle: listItem.Category,
            filterType: Strings.assetCategoryCapabilities
          });
          capMaxSuggestionLimit = capMaxSuggestionLimit + 1;
        }
      }
      if (
        listItem.Subcategory.toLowerCase().includes(searchInput?.toLowerCase())
      ) {
        if (
          !subCategoryProviders.find(
            eachSubCatProviders =>
              eachSubCatProviders.subTitle === listItem.Subcategory
          ) &&
          capMaxSuggestionLimit + maxSuggestionLimit < 10
        ) {
          subCategoryProviders.push({
            key: Strings.capabilities,
            title: listItem.Subcategory + " " + Strings.providers,
            subTitle: listItem.Subcategory,
            filterType: Strings.assetSubCategoryProviders
          });
          capMaxSuggestionLimit = capMaxSuggestionLimit + 1;
        }

        if (
          !subCategoryCapabilities.find(
            eachSubCatCapabilities =>
              eachSubCatCapabilities.subTitle === listItem.Subcategory
          ) &&
          capMaxSuggestionLimit + maxSuggestionLimit < 10
        ) {
          subCategoryCapabilities.push({
            key: Strings.capabilities,
            title: listItem.Subcategory + " " + Strings.assets,
            subTitle: listItem.Subcategory,
            filterType: Strings.assetSubCategoryCapabilities
          });
          capMaxSuggestionLimit = capMaxSuggestionLimit + 1;
        }
      }
    });

    getSuggestionLimit(capMaxSuggestionLimit + maxSuggestionLimit);

    allDataArray.push(
      ...assetName,
      ...categoryProviders,
      ...categoryCapabilities,
      ...subCategoryProviders,
      ...subCategoryCapabilities
    );

    getListsDataCapabilities(allDataArray);

    setListItems({
      uniqueAssetName: assetName,
      uniqueCategoryProviders: categoryProviders,
      uniqueCategoryCapabilities: categoryCapabilities,
      uniqueSubCategoryProviders: subCategoryProviders,
      uniqueSubCategoryCapabilities: subCategoryCapabilities
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [capabilitiesList, searchInput, maxSuggestionLimit]);

  return (
    <Fragment>
      {listItems &&
        Object.keys(listItems).map((keyType, index) => {
          return (
            <Fragment key={keyType}>
              {keyType &&
                listItems[keyType].map((item, index1) => {
                  rowIndex++;
                  return (
                    <OptionsListItem
                      key={index1 + index}
                      currentOption={currentOption}
                      currentRow={rowIndex}
                      onClick={() => getRowData(item)}
                      data-test="options-list-item">
                      {item.title}
                    </OptionsListItem>
                  );
                })}
            </Fragment>
          );
        })}
    </Fragment>
  );
};

CapabilitiesListItem.propTypes = {
  capabilitiesList: PropTypes.arrayOf(Object),
  searchInput: PropTypes.string,
  currentOption: PropTypes.number,
  setFilteredOptions: PropTypes.func,
  setSearchInput: PropTypes.func,
  getData: PropTypes.func,
  maxSuggestionLimit: PropTypes.number,
  getListsDataCapabilities: PropTypes.func,
  getSuggestionLimit: PropTypes.func
};

export default CapabilitiesListItem;
