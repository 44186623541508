import styled from "styled-components";

export const CardImage = styled.img`
  object-fit: ${props => (props.isObjectContain ? "contain" : "cover")};
  height: ${props => props.height};
  width: ${props => props.width};
  @media ${props => props.theme.breakPoints.mobileDevice} {
    height: ${props => props.mobHeight};
    width: ${props => props.mobWidth};
  }
`;
