import React from "react";
import PropTypes from "prop-types";

import CustomLink from "../Link/Link";
import LineSeparator from "../LineSeparator/LineSeparator";
import Strings from "../../../resources/strings";
import { convertToUpperCase } from "../../../utils/commonUtils";
import ImageCard from "../ImageCard/ImageCard";
import Images from "../../../resources/images";
import { DictionaryContext } from "../../../providers/DictionaryContext";
import {
  GridContainer,
  GridContainerTitle,
  GridLayoutContainer,
  GridCustomLink,
  GridCustomLinkMore,
  GridRedirectText,
  CardGripWrapper
} from "./style";

/**
 * Used to get the grid columns for card along with title and redirect link with wrap property
 * @param {node} children child card elements
 * @param {string} title grid title
 * @param {string} linkText custom link text
 * @param {string} redirectTo custom link to redirect
 * @param {boolean} isScroll to handle scroll in mobile view
 * @param {boolean} noTitle
 * @returns card grid component
 */
const CardGrid = props => {
  const { children, title, linkText, redirectTo, isScroll, noTitle } = props;
  return children?.length || noTitle ? (
    <GridContainer>
      <GridContainerTitle>{title}</GridContainerTitle>
      <GridLayoutContainer isScroll={isScroll}>{children}</GridLayoutContainer>
      {redirectTo ? (
        <CardGripWrapper>
          <GridCustomLink>
            <GridCustomLinkMore>
              <DictionaryContext.Consumer>
                {({ dictionary }) => {
                  return convertToUpperCase(dictionary?.more || Strings.more);
                }}
              </DictionaryContext.Consumer>
            </GridCustomLinkMore>
            <CustomLink to={redirectTo}>
              <GridRedirectText>
                {convertToUpperCase(linkText)}
                <ImageCard
                  img={Images.RightArrow}
                  width="16px"
                  height="16px"
                  alt="arrow"></ImageCard>
              </GridRedirectText>
            </CustomLink>
          </GridCustomLink>
          <LineSeparator />
        </CardGripWrapper>
      ) : null}
    </GridContainer>
  ) : null;
};

CardGrid.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  linkText: PropTypes.string,
  redirectTo: PropTypes.string,
  isScroll: PropTypes.bool,
  noTitle: PropTypes.bool
};

export default CardGrid;
