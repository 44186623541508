import React from "react";
import PropTypes from "prop-types";

import { RedirectLink } from "./style";
import ImageCard from "../ImageCard/ImageCard";
import Images from "../../../resources/images";

/**
 * Custom Read More CTA to handle the custom links
 * @param {string} redirectTo to which URL to redirect
 * @param {string} linkText text to show in the link
 * @param {string} textcolor link text color
 */

const ReadMoreCTA = ({ redirectTo, linkText, textcolor }) => {
  return (
    <RedirectLink to={redirectTo} textcolor={textcolor}>
      {linkText}
      <ImageCard
        img={textcolor ? Images.RightArrow : Images.WhiteRightArrow}
        width="16px"
        height="16px"
        mobWidth="16px"
        mobHeight="16px"
        alt="arrow"></ImageCard>
    </RedirectLink>
  );
};

ReadMoreCTA.propTypes = {
  redirectTo: PropTypes.string,
  linkText: PropTypes.string,
  textcolor: PropTypes.string
};

export default ReadMoreCTA;
