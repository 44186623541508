import React, { Fragment } from "react";
import PropTypes from "prop-types";

import Strings from "../../../resources/strings";
import { convertToUpperCase, fetchNameById } from "../../../utils/commonUtils";
import IconTextButton from "../../CommonComponents/IconTextButton/IconTextButton";
import { DictionaryContext } from "../../../providers/DictionaryContext";
import {
  CardWrapper,
  CardTitle,
  CardLinkWrapper,
  CardDescription
} from "../../../styles/commonStyles";
import {
  AssetHeadingWrapper,
  AssetContentWrapper,
  AssetCardTitle,
  AssetCardWrapper,
  AssetCardTitleWrapper
} from "./style";
/**
 * AssetCard component which can be use to show details of Assets
 * @param {Object} capabilities
 * @param {Array} providers
 * @param {Array} capabilitiesCategory
 * @param {Function} handleModal
 * @param {Boolean} filtered
 */
const AssetCard = ({
  capabilities,
  handleModal,
  filtered,
  providers,
  capabilitiesCategory
}) => {
  const { ProvidersLookupId, Category, Subcategory } = capabilities;
  return (
    <DictionaryContext.Consumer>
      {({ dictionary }) => {
        return (
          <Fragment>
            <CardWrapper>
              <AssetCardTitleWrapper filtered={filtered}>
                <AssetCardTitle>
                  {convertToUpperCase(
                    fetchNameById(
                      providers,
                      ProvidersLookupId,
                      dictionary?.companyId || Strings.companyId
                    )[0]?.Title
                  )}
                </AssetCardTitle>
                <AssetCardTitle>
                  {filtered ? convertToUpperCase(Subcategory) : ""}
                </AssetCardTitle>
              </AssetCardTitleWrapper>
              <AssetCardWrapper>
                <AssetHeadingWrapper>
                  <CardTitle>{Category}</CardTitle>
                </AssetHeadingWrapper>
                <AssetContentWrapper>
                  <CardDescription>
                    {
                      fetchNameById(
                        capabilitiesCategory,
                        Category?.trim(),
                        dictionary?.title || Strings.title
                      )[0]?.Description
                    }
                  </CardDescription>
                  <CardLinkWrapper>
                    <IconTextButton
                      onClick={handleModal}
                      textcolor="blue"
                      linkText={dictionary?.readMore || Strings.readMore}
                    />
                  </CardLinkWrapper>
                </AssetContentWrapper>
              </AssetCardWrapper>
            </CardWrapper>
          </Fragment>
        );
      }}
    </DictionaryContext.Consumer>
  );
};

AssetCard.propTypes = {
  capabilities: PropTypes.object,
  handleModal: PropTypes.func,
  filtered: PropTypes.bool,
  providers: PropTypes.array,
  capabilitiesCategory: PropTypes.array
};

export default AssetCard;
