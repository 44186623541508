import React from "react";
import PropTypes from "prop-types";

import ReadMoreCTA from "../CommonComponents/ReadMoreCTA/ReadMoreCTA";
import { CardTags } from "../../styles/commonStyles";
import {
  SummaryCardTagWrapper,
  SummaryDescriptionWrapper,
  SummaryDescription,
  SummaryWrapper
} from "./style";

/**
 * DetailsSummaryVariation component renders summary description and summary tags when search component is used.
 * @param {String} description
 * @param {Array} filteredHighlights
 * @param {String} linkText
 * @param {String} redirectTo
 * @example 
 * DetailsSummaryVariation({
    description})
 */
const DetailsSummaryVariation = props => {
  const { description, filteredHighlights, redirectTo, linkText } = props;
  return (
    <>
      <SummaryDescriptionWrapper>
        <SummaryDescription>{description}</SummaryDescription>
      </SummaryDescriptionWrapper>
      <SummaryWrapper>
        <SummaryCardTagWrapper>
          {filteredHighlights?.map((highlight, index) => {
            return <CardTags key={index}>{highlight?.LookupValue}</CardTags>;
          })}
        </SummaryCardTagWrapper>
        <ReadMoreCTA
          redirectTo={redirectTo}
          textcolor="blue"
          linkText={linkText}
        />
      </SummaryWrapper>
    </>
  );
};

DetailsSummaryVariation.propTypes = {
  description: PropTypes.string,
  filteredHighlights: PropTypes.array,
  linkText: PropTypes.string,
  textcolor: PropTypes.string,
  redirectTo: PropTypes.string
};

export default DetailsSummaryVariation;
