import styled from "styled-components";
import { CardDescription } from "../../../styles/commonStyles";

export const AssetCardTitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
  margin-bottom: ${props => (props.filtered ? "10px" : "20px")};
  min-height: ${props => (props.filtered ? "59px" : "unset")};
`;

export const AssetHeadingWrapper = styled.div`
  padding: 19px 20px 0px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AssetContentWrapper = styled.div`
  padding: 10px 20px 0px 20px;
  & > ${CardDescription} {
    min-height: 60px;
  }
`;

export const AssetDetails = styled.div`
  margin-top: 24px;
`;

export const AssetCardTitle = styled.h3`
  font-size: ${props => props.theme.fontSize.secondary};
  font-weight: ${props => props.theme.fontWeight.normal};
  line-height: ${props => props.theme.lineHeight.default};
  color: ${props => props.theme.fontColor.inactiveBlack};
  margin: 20px 0 0px 20px;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  space-wrap: nowrap;
`;

export const AssetCardWrapper = styled.div``;
