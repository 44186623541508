import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";

import LineSeparator from "../CommonComponents/LineSeparator/LineSeparator";
import ProvidersListItem from "./ProvidersListItem";
import CapabilitiesListItem from "./CapabilitiesListItem";
import { OptionsListWrapper, OptionsTotalList } from "./style";

const OutsideClickHandler = (ref, setFilteredOptions) => {
  useEffect(() => {
    function checkOutsideClick(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setFilteredOptions({
          filteredProviderOptions: [],
          filteredCapabilitiesOptions: []
        });
      }
    }
    document.addEventListener("click", checkOutsideClick);
  }, [ref]);
};

/**
 * OptionsList component is used to render the Search Options list when user provides some input in search box.
 * @param {array} filteredOptions
 * @param {number} currentOption
 * @param {number} searchInput
 * @example OptionsList({ 
    filteredOptions,
    currentOption,
    isKeyPressed})
 */
const OptionsList = props => {
  const {
    filteredOptions,
    currentOption,
    searchInput,
    setFilteredOptions,
    setSearchInput,
    getData,
    getListsDataProviders,
    getListsDataCapabilities,
    getSuggestionLimit
  } = props;

  const [maxSuggestionLimit, setMaxSuggestionLimit] = useState(-1);

  const getMaxSuggestionLimit = index => {
    setMaxSuggestionLimit(index);
  };

  const clickRef = useRef(null);
  OutsideClickHandler(clickRef, setFilteredOptions);

  return (
    <OptionsListWrapper>
      <LineSeparator />
      <OptionsTotalList ref={clickRef}>
        {filteredOptions.filteredProviderOptions && (
          <ProvidersListItem
            providersList={filteredOptions.filteredProviderOptions}
            currentOption={currentOption}
            searchInput={searchInput}
            setFilteredOptions={setFilteredOptions}
            setSearchInput={setSearchInput}
            getData={getData}
            getMaxSuggestionLimit={getMaxSuggestionLimit}
            getListsDataProviders={getListsDataProviders}
            data-test="total-list"
          />
        )}
        {filteredOptions.filteredCapabilitiesOptions &&
          maxSuggestionLimit >= 0 && (
            <CapabilitiesListItem
              capabilitiesList={filteredOptions.filteredCapabilitiesOptions}
              searchInput={searchInput}
              currentOption={currentOption}
              setFilteredOptions={setFilteredOptions}
              setSearchInput={setSearchInput}
              getData={getData}
              maxSuggestionLimit={maxSuggestionLimit}
              getListsDataCapabilities={getListsDataCapabilities}
              getSuggestionLimit={getSuggestionLimit}
            />
          )}
      </OptionsTotalList>
    </OptionsListWrapper>
  );
};

OptionsList.propTypes = {
  currentOption: PropTypes.number,
  searchInput: PropTypes.string,
  setFilteredOptions: PropTypes.func,
  setSearchInput: PropTypes.func,
  getData: PropTypes.func,
  filteredOptions: PropTypes.instanceOf(Object),
  getListsDataProviders: PropTypes.func,
  getListsDataCapabilities: PropTypes.func,
  getSuggestionLimit: PropTypes.func
};

export default OptionsList;
