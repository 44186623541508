import React, { Fragment } from "react";
import PropTypes from "prop-types";

import CardGrid from "../CommonComponents/CardGrid/CardGrid";
import ProviderCard from "../Cards/ProviderCard/ProviderCard";
import Strings from "../../resources/strings";
import { DictionaryContext } from "../../providers/DictionaryContext";
import { ResultsText } from "./style";

/**
 * ProvidersFilterResults is used to render the filtered providers.
 * @param {Array} filterResult
 * @param {String} filterText
 * @param {Object} searchedData
 * @returns
 */
const ProvidersFilterResults = props => {
  const { filterResult, filterText, searchedData } = props;

  return (
    <Fragment>
      {filterResult && filterResult.length ? (
        !filterText ? (
          <DictionaryContext.Consumer>
            {({ dictionary }) => {
              return (
                <ResultsText filterText={filterText}>
                  {filterResult.length +
                    " " +
                    (dictionary?.multiResults || Strings.multiResults)}
                </ResultsText>
              );
            }}
          </DictionaryContext.Consumer>
        ) : (
          <ResultsText>{filterText}</ResultsText>
        )
      ) : null}
      <CardGrid>
        {filterResult &&
          filterResult?.map((data, index) => {
            return (
              <ProviderCard
                headingBgColor={true}
                key={index}
                provider={{
                  ...data,
                  CategoryArray: searchedData?.filtertype?.contains(
                    Strings.category
                  )
                    ? data?.CategoryArray?.filter(
                        category =>
                          category.toLowerCase() ===
                          searchedData?.subTitle0?.toLowerCase()
                      )
                    : data?.CategoryArray
                }}
                subDescription={data.toString()}
                redirectTo={
                  "/auth/providers/details?id=" + data?.id
                }></ProviderCard>
            );
          })}
      </CardGrid>
    </Fragment>
  );
};
ProvidersFilterResults.propTypes = {
  filterResult: PropTypes.arrayOf(Object),
  filterText: PropTypes.string,
  searchedData: PropTypes.object
};

export default ProvidersFilterResults;
