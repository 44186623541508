import styled from "styled-components";

import { AssetDescription, AssetTitle } from "../../../styles/commonStyles";

export const AssetCategoryDetailsWrapper = styled.div`
  width: 100%;
  margin-top: 10px;
  & > :first-child {
    padding-bottom: 42px;
    position: sticky;
    top: -3px;
    background: #ffffff;
    z-index: 998;
    display: flex;
    align-items: center;
  }
  @media ${props => props.theme.breakPoints.tabDevice} {
    margin-top: 56px;
    & > :first-child {
      padding-bottom: 12px;
      position: initial;
    }
  }
  @media ${props => props.theme.breakPoints.mobileDevice} {
    margin-top: 56px;
    & > :first-child {
      padding-bottom: 12px;
      position: initial;
    }
  }
`;
export const AssetWrapper = styled.ul`
  margin-left: 47px;
  padding: 0;
`;
export const CategoryTitle = styled(AssetTitle)`
  font-size: ${props => props.theme.fontSize.heading};
  color: ${props => props.theme.fontColor.secondary};
  @media ${props => props.theme.breakPoints.tabDevice} {
    line-height: ${props => props.theme.lineHeight.heading};
  }
  @media ${props => props.theme.breakPoints.mobileDevice} {
    line-height: ${props => props.theme.lineHeight.heading};
  }
`;
export const CategoryDescription = styled(AssetDescription)`
  margin: 25px 0 60px;
  @media ${props => props.theme.breakPoints.tabDevice} {
    margin: 16px 0 60px;
  }
  @media ${props => props.theme.breakPoints.mobileDevice} {
    margin: 16px 0 60px;
  }
`;
// Asset-sub-category-styles
export const SubCategoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 56px;
`;
export const TitleWrapper = styled.div`
  display: flex;
  scroll-margin: 52px;
`;
export const SubCategoryTitle = styled(AssetTitle)`
  font-size: ${props => props.theme.fontSize.subHeading};
  margin: 0 0 20px 16px;
  @media ${props => props.theme.breakPoints.tabDevice} {
    margin: 0 0 16px 16px;
  }
  @media ${props => props.theme.breakPoints.mobileDevice} {
    margin: 0 0 16px 16px;
  }
`;
export const SubCategoryDescription = styled(AssetDescription)`
  margin: 0 0 34px 47px;
`;
