import React, { useState, useEffect } from "react";
import propTypes from "prop-types";

import ImageCard from "../CommonComponents/ImageCard/ImageCard";
import Images from "../../resources/images";
import LineSeparator from "../CommonComponents/LineSeparator/LineSeparator";
import RichText from "../CommonComponents/RichText/RichText";
import {
  AccordionContainer,
  AccordionWrapper,
  Dropdown,
  Description,
  TitleWrapper,
  AccordionTitle
} from "./style";
import Strings from "../../resources/strings";

/**
 * Accordion component will shows the title and description
 * @param {string} title
 * @param {string} description
 * @returns
 */
const Accordion = props => {
  const {
    title,
    description,
    children,
    filter,
    isSelected,
    accordionAssetName
  } = props;
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    accordionAssetName?.length &&
      document.querySelector(`#${Strings.title}`) &&
      document
        .querySelector(`#${Strings.title}`)
        .scrollIntoView({ behavior: "smooth" });
  }, [accordionAssetName]);

  return (
    <AccordionContainer>
      {description.length || children.props ? (
        <AccordionWrapper
          onClick={() => setIsActive(!isActive)}
          data-test="accordion-click">
          <TitleWrapper
            isActive={isActive}
            filter={filter}
            id={accordionAssetName ? Strings.title : ""}>
            <AccordionTitle filter={filter} isSelected={isSelected}>
              {title}
            </AccordionTitle>
            <ImageCard
              img={Images.DownArrow}
              width="10px"
              height="16px"
              mobWidth="10px"
              mobHeight="16px"
              alt="arrow"
            />
          </TitleWrapper>
          {!isActive && <LineSeparator />}
        </AccordionWrapper>
      ) : (
        ""
      )}
      {isActive && (
        <Dropdown>
          {description && (
            <Description>
              <RichText>{description}</RichText>
            </Description>
          )}
          {children}
          <LineSeparator />
        </Dropdown>
      )}
    </AccordionContainer>
  );
};

Accordion.propTypes = {
  title: propTypes.string,
  description: propTypes.string,
  children: propTypes.node
};
Accordion.defaultProps = {
  title: "Algorithm",
  description:
    "A proper to IOT Asset management is required for the businees to monitor and manage the assets using a system approach.The benifits from asset management includes imporvement."
};
export default Accordion;
