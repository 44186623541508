import React, { useEffect, useState } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ThemeProvider } from "styled-components";

import Layout from "./src/components/Layout/Layout";
import Theme from "./src/styles/theme/theme";
import GlobalStyles from "./src/styles/theme/globalStyles";
import { checkSession } from "./src/utils/auth/auth";
import Strings from "./src/resources/strings";
import { isBrowser } from "./src/utils/commonUtils";
import { StateContext } from "./src/providers/StateContext";
import { DictionaryContext } from "./src/providers/DictionaryContext";

/**
 * SessionCheck({children}) fetches the current session.
 * @param {*} param0
 * @example SessionCheck(<App />)
 */
const SessionCheck = ({ children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      checkSession(() => setLoading(false));
    }, 1000);
  });

  return loading === false ? <>{children}</> : null;
};

/**
 * onRouteUpdate to reset the pagination values in landing page
 */
export const onRouteUpdate = ({ location }) => {
  let keysToRemove = [Strings.capabilities];
  if (isBrowser) {
    if (
      location.pathname === "/auth/explore" ||
      location.pathname === "/auth/"
    ) {
      keysToRemove.forEach(key => sessionStorage.removeItem(key));
    } else if (location.pathname === "/auth/providers") {
      sessionStorage.removeItem(keysToRemove[0]);
    }
  }
};

/**
 * onInitialClientRender used to hide the loader on page load
 */
export const onInitialClientRender = () => {
  setTimeout(function () {
    document.getElementById("___loader").style.display = "none";
  }, 1200);
};

/**
 * AppTheme({ element }) is used to apply dark or light theme to the components.
 * @param { element }
 * @example
 * AppTheme({ dashboard })
 */
const AppTheme = ({ element }) => {
  return (
    <SessionCheck>
      <ThemeProvider theme={Theme}>
        <GlobalStyles />
        {element}
      </ThemeProvider>
    </SessionCheck>
  );
};

/**
 * wrapRoot Element wraps the page root element and add the themes above to that
 * @param {element}
 */
export const wrapRootElement = ({ element }) => <AppTheme element={element} />;

/**
 * Function to generate the context for application data
 * @param {Node} children
 * @param {Object} value
 * @returns
 */

const DataProvider = ({ children, value }) => {
  const { pageContext } = value;
  return (
    <DictionaryContext.Provider
      value={{
        dictionary: pageContext.dictionary // dictionary table
      }}>
      <StateContext.Provider
        value={{
          providers: pageContext.providers, // contains all providers
          capabilities: pageContext.capabilities, // contains all capabilities
          lookups: pageContext.lookups, // contains all continents and countries
          providersIndustry: pageContext.lookups.providersIndustry, // contains all provider industry
          providersCategory: pageContext.lookups.providersCategory, // contains all provider category
          categoryCount: pageContext.categoryCount, // contains providers count for each category
          capabilitiesCategory: pageContext.capabilitiesCategory, // contains capabilities data mapped with provider
          uniqueCapabilitiesCategory: pageContext.uniqueCapabilitiesCategory, // contains capabilities data mapped with provider without duplicate capabilities subcategory
          uniqueCapabilities: pageContext.uniqueCapabilities, // contains capabilities data mapped with provider without duplicate capabilities category
          contacts: pageContext.contacts, // contains contacts data list
          highlights: pageContext.highlights, // contains highlights list for providers
          caseStudy: pageContext.caseStudy, //contains case study data
          guideCards: pageContext.guideCards // contains data for quick tour flow
        }}>
        {children}
      </StateContext.Provider>
    </DictionaryContext.Provider>
  );
};

/**
 * wrapPage Element wraps the page element and add the data
 * @param {Node} element
 * @param {Object} props
 */
export const wrapPageElement = ({ element, props }) => (
  <DataProvider value={props}>
    <Layout showSearch={!window.location.pathname.includes(Strings.details)}>
      {element}
    </Layout>
  </DataProvider>
);
