import styled from "styled-components";

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: flex-end;
  position: sticky;
  top: 0;
  z-index: 99;
  min-height: 120px;
  background-color: ${props => props.theme.backgroundColor.primary0};
  @media ${props => props.theme.breakPoints.tabDevice} {
    justify-content: space-between;
    min-height: 80px;
    padding: 0 21px;
    width: 100%;
    position: fixed;
    left: 0;
  }
  @media ${props => props.theme.breakPoints.mobileDevice} {
    justify-content: space-between;
    min-height: 80px;
    padding: 0 21px;
    width: 100%;
    position: fixed;
    left: 0;
  }
`;

export const Avatar = styled.img`
  width: 40px;
  height: 40px;
`;

export const LogoWrapper = styled.img`
  display: none;
  width: 40px;
  height: 48px;
  object-fit: cover;
  @media ${props => props.theme.breakPoints.tabDevice} {
    display: block;
  }
  @media ${props => props.theme.breakPoints.mobileDevice} {
    display: block;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: ${props => (props.showSearch ? "flex-start" : "center")};
  width: 100%;
  margin: 40px 40px 0px 79px;
  @media ${props => props.theme.breakPoints.mobileDevice} {
    margin: 40px 15px 0px 5px;
  }
  @media ${props => props.theme.breakPoints.mediumDesktopDevice} {
    margin: 40px 20px 0px 30px;
  }
  @media ${props => props.theme.breakPoints.smallDesktopDevice} {
    margin: 40px 15px 0px 15px;
  }
  @media ${props => props.theme.breakPoints.tabDevice} {
    margin: 40px 15px 0px 10px;
  }
`;

export const MobileWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 17px 0 29px 0;
`;

export const MobileHeaderWrapper = styled.div`
  display: none;
  @media ${props => props.theme.breakPoints.mobileDevice} {
    display: flex;
    flex-direction: column;
    width: 100%;
    & > :last-child {
      margin-bottom: ${props => !props.showSearch && "14px"};
    }
  }
  @media ${props => props.theme.breakPoints.tabDevice} {
    display: flex;
    flex-direction: column;
    width: 100%;
    & > :last-child {
      margin-bottom: ${props => !props.showSearch && "14px"};
    }
  }
`;

export const DesktopHeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  @media ${props => props.theme.breakPoints.mobileDevice} {
    display: none;
  }
  @media ${props => props.theme.breakPoints.tabDevice} {
    display: none;
  }
`;
