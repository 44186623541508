import React from "react";

import dompurify from "dompurify";

import { RichTextWrapper } from "./style";

/**
 * Component to sanitize and render the HTML data
 * @param {Node} children
 * @returns
 */
const RichText = ({ children }) => {
  const sanitizer = dompurify.sanitize;
  return (
    <RichTextWrapper
      dangerouslySetInnerHTML={{
        __html: sanitizer(children, { ADD_ATTR: ["target"] })
      }}></RichTextWrapper>
  );
};

export default RichText;
