import React from "react";

import { Separator } from "./style";

/**
 * used to achieve the separator line between contents
 * @param {}
 * @returns separator
 */
const LineSeparator = () => <Separator />;

export default LineSeparator;
