const Theme = {
  fontColor: {
    activeBlack: "#1C1C28",
    inactiveBlack: "#8F90A6",
    white: "#FFFFFF",
    linkColor: "#4151D9",
    hover: "#5362db",
    btnDisabled: "rgba(65, 81, 217, 0.65)",
    disabled: "rgba(255, 255, 255, 0.5)",
    primary: "#ffffff",
    secondary: "#000000",
    manatee: "#888a97",
    darkGrey: "#555770",
    description: "#8e90a6",
    grey: "#61626a",
    placeholderColor: "#b0b1c0"
  },
  backgroundColor: {
    cardBgColor: "#f8f9f9",
    loaderBgColor: "#ffffff",
    loader: "rgba(0, 0, 255, 0.2)",
    transparentBlack: "rgba(0,0,0,0.3)",
    tagBgColor: "#F1F3F7",
    white: "#FFFFFF",
    activeBtn: "#4051D8",
    hover: "#5362db",
    disabled: "rgba(64, 81, 216, 0.5)",
    primary: "#f2f2f7",
    primary0: "#f1f3f7",
    overlay: "rgba(0, 0, 0, 0.5)",
    bannerLogoBg: "rgba(208, 208, 208, 0.4)",
    card: "#ffffff",
    pacificBlue: "#00a1e0",
    blue: "#4151d9",
    greyBtn: " #e0e2e9",
    transparent: "transparent",
    greyListItem: "#808080"
  },
  border: {
    royalBlue: "#4151d9",
    btnDisabled: "rgba(65, 81, 217, 0.65)",
    hover: "#5362DB",
    divider: "rgba(199, 199, 213, 0.5)",
    inputBorder: "solid 1px #8a8a94",
    table: "#979797"
  },
  fontFamily: {
    default: `system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue",Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji","Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`
  },
  fontSize: {
    extrasmall: "10px",
    small: "11px",
    tertiary: "12px",
    medium: "13px",
    secondary: "14px",
    primary: "16px",
    quaternary: "18px",
    subHeading: "24px",
    heading: "28px",
    sdheading: "32px",
    mdheading: "36px",
    xlheading: "40px",
    connect: "20px",
    secondaryHeading: "21px"
  },
  fontWeight: {
    normal: 400,
    medium: 500,
    bold: 600,
    bolder: 700
  },
  lineHeight: {
    tertiary: "12px",
    small: "14px",
    normal: "16px",
    medium: "17px",
    secondary: "18px",
    default: "20px",
    description: "24px",
    content: "26px",
    primary: "28px",
    large: "32px",
    heading: "40px",
    extraLarge: "52px",
    connect: "36px"
  },
  letterSpacing: {
    zero: 0
  },
  breakPoints: {
    smallMobileDevice: "screen and (min-width: 360px) and (max-width: 400px)",
    mobileDevice: "screen and (max-width: 768px)",
    tabDevice: " screen and (min-width: 768px) and (max-width: 991px)",
    smallDesktopDevice: "screen and (min-width: 992px) and (max-width: 1023px)",
    mediumDesktopDevice:
      "screen and (min-width: 1024px) and (max-width: 1280px)",
    desktopDevice: "screen and (min-width: 1240px)",
    xlDesktopDevice: "screen and (min-width: 1280px) and (max-width: 1439px)",
    largeDesktopDevice: "screen and (min-width: 1281px)",
    xlMediumDesktopDevice: "screen and (min-width: 1440px)",
    extraLargeDesktopDevice: "screen and (min-width: 1660px)"
  },
  shadow: {
    bannerTitle: "0 2px 2px rgba(0, 0, 0, 0.2)",
    card: "0 10px 30px -5px rgba(0, 0, 0, 0.06)"
  },
  boxShadow: {
    container: "0 10px 30px -5px rgb(0 0 0 / 6%)",
    search: "0 8px 24px -20px rgb(68 70 112 / 30%)",
    card: "0 6px 8px 0 rgba(0, 0, 0, 0.08), 0 0 0 1px rgba(0, 0, 0, 0.04)"
  },
  gradient: {
    /* gradient styling goes here. */
  }
};

export default Theme;
