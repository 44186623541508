import styled from "styled-components";

import { Link } from "gatsby";

export const RedirectLink = styled(Link)`
  margin: 0 4px 0 0;
  text-decoration: none;
  font-size: ${props => props.theme.fontSize.secondary};
  font-weight: ${props => props.theme.fontWeight.bold};
  line-height: ${props => props.theme.lineHeight.normal};
  color: ${props => props.textcolor || props.theme.fontColor.white};
  cursor: pointer;
  display: flex;
  align-items: center;
  & > img {
    padding-top: 2px;
  }
  &:hover {
    color: ${props => props.textcolor || props.theme.fontColor.white};
  }
`;
