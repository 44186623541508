import { getAuthToken } from "../utils/auth/auth";
import { decryptData, encryptData } from "./security";
import { apiInstance, authInstance } from "./services";

/**
 * authApiCall used to fetch the authToken for azure APIs
 * @param {}
 * @example authApiCall()
 */
export const authApiCall = async () => {
  const token = await getAuthToken();
  let access_Token = {
    userAccessToken: token || " "
  };
  return authInstance
    .get("/GetAccessToken", { params: access_Token })
    .then(res => {
      if (res && res.data && res.data.responseData && window) {
        sessionStorage.setItem(
          "apiOAuthToken",
          res.data.responseData.apiOAuthToken
        );
        return res.data.responseData.apiOAuthToken;
      }
    })
    .catch(err => {
      return err;
    });
};

/**
 * getApiCall will contains all the azure get calls
 * @example getApiCall.method()
 */
export const getApiCall = {
  getUserInformation: userId => {
    let userData = {
      userid: userId
    };
    return apiInstance
      .get("/GetUserInformation", { params: userData })
      .then(res => {
        if (res && res.data) {
          return decryptData({ data: res.data.responseData });
        }
      })
      .catch(err => {
        return err;
      });
  },
  getPopularCards: type => {
    let encryptType = encryptData({ data: type });
    let data = {
      type: encryptType
    };
    return apiInstance
      .get("/GetPopularCards", { params: data })
      .then(res => {
        if (res && res.data) {
          return decryptData({ data: res.data.responseData });
        }
      })
      .catch(err => {
        return err;
      });
  }
};

/**
 * postApiCall will contains all the azure post calls
 * @example postApiCall.method()
 */
export const postApiCall = {
  postUserInformation: ({ userId, loginTime, logoutTime, guideCompleted }) => {
    const userData = {
      userid: decryptData({ data: userId }),
      loginTime: loginTime,
      logoutTime: logoutTime,
      guideCompleted: guideCompleted
    };
    return apiInstance
      .post("/PostUserInformation", encryptData({ data: userData }))
      .then(res => {
        if (res && res.data) {
          return res.data.responseData;
        }
      })
      .catch(err => {
        return err;
      });
  },
  postPopularCards: ({
    type,
    userId,
    listId,
    itemId,
    clickedTime,
    createdTime
  }) => {
    const userData = {
      userid: decryptData({ data: userId }),
      type: type,
      listid: listId,
      itemid: itemId,
      clickedTime: clickedTime,
      createdTime: createdTime
    };
    return apiInstance
      .post("/PostPopularCards", encryptData({ data: userData }))
      .then(res => {
        if (res && res.data) {
          return res.data.responseData;
        }
      })
      .catch(err => {
        return err;
      });
  },
  postSearchInput: ({ searchKeyWord, userId, page, searchedTime }) => {
    const userData = {
      userid: decryptData({ data: userId }),
      searchKeyWord: searchKeyWord,
      page: page,
      searchedTime: searchedTime
    };
    return apiInstance
      .post("/PostSearchInput", encryptData({ data: userData }))
      .then(res => {
        if (res && res.data) {
          return res.data.responseData;
        }
      })
      .catch(err => {
        return err;
      });
  },
  postContactForm: ({
    userName,
    userId,
    subject,
    description,
    page,
    relationalManager
  }) => {
    const userData = {
      userid: decryptData({ data: userId }),
      username: userName,
      subject: subject,
      description: description,
      relationalManager: relationalManager,
      page: page
    };
    return apiInstance
      .post("/PostContactForm", encryptData({ data: userData }))
      .then(res => {
        if (res && res.data) {
          return res.data;
        }
      })
      .catch(err => {
        return err;
      });
  }
};
