import React from "react";
import PropTypes from "prop-types";

import {
  EmployeeList,
  EmployeeListItem,
  EmployeeDescription,
  EmpName,
  EmpDesignation,
  EmployeeImage
} from "./style";
import { getRefactoredImageUrl } from "../../utils/commonUtils";

/**
 * Employees component is used to render employee name and description in details summary component
 * @param {Array} mainContact
 * @example
 * Employees({
   mainContact}) 
 */
const Employees = props => {
  const { mainContact } = props;
  return (
    <EmployeeList>
      {mainContact.map((contact, index) => {
        return (
          <EmployeeListItem key={index}>
            <EmployeeImage
              src={getRefactoredImageUrl(contact?.ProfileImage?.Url)}
              alt="employee-image"
            />
            <EmployeeDescription>
              <EmpName>{contact?.Name}</EmpName>
              <EmpDesignation>{contact?.Title}</EmpDesignation>
            </EmployeeDescription>
          </EmployeeListItem>
        );
      })}
    </EmployeeList>
  );
};

Employees.propTypes = {
  mainContact: PropTypes.array
};

export default Employees;
