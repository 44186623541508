import React from "react";
import PropTypes from "prop-types";

import Images from "../../resources/images";
import Image from "../CommonComponents/ImageCard/ImageCard";
import { StyledModal, PageModalWrapper } from "./style";
import { CloseButton } from "../../styles/commonStyles";

/* PageModal is used to get the details of the Asset in modal when the user clicks any Asset button
 * @param {showModal} title -show the Modal when clicks the Asset
 * @param {closeModal} content - Close the Modal when clicks the close button
 * @param {bool} hideBtn - to hide the close icon from page modal
 * @param {bool} filter - is it use for filter or other page
 */
const PageModal = props => {
  const { showModal, closeModal, modalRef, children, filter, hideBtn } = props;
  return (
    <PageModalWrapper ref={modalRef}>
      <StyledModal
        show={showModal}
        onHide={closeModal}
        id="detailsPopupId"
        scrollable={true}
        filter={filter}>
        <StyledModal.Body filter={filter}>
          {!hideBtn && (
            <CloseButton
              className="btn btn-transparent p-0"
              onClick={closeModal}
              filter={filter}>
              <Image
                img={Images.CloseBlack}
                alt="close icon"
                height={filter ? "24px" : "32px"}
                width={filter ? "24px" : "32px"}
                mobWidth={filter ? "24px" : "32px"}
                mobHeight={filter ? "24px" : "32px"}
              />
            </CloseButton>
          )}

          {children}
        </StyledModal.Body>
      </StyledModal>
    </PageModalWrapper>
  );
};

PageModal.propTypes = {
  showModal: PropTypes.bool,
  closeModal: PropTypes.func,
  hideBtn: PropTypes.bool
};

export default PageModal;
